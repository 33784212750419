import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  FoldersRequestBody,
  FoldersResponseMessage,
  LanguageRequestBody,
  LanguageResponseMessage,
  CategoriesRequestBody,
  CategoriesResponseMessage,
  ContentLinksRequestBody,
  ContentLinksResponseMessage,
  ContentDetailsRequestBody,
  ContentDetailsResponseMessage,
  UpdatePersonalMessageRequestBody,
  UpdatePersonalMessageResponseMessage,
  GenerateQRCodeRequestBody,
  GenerateQRCodeResponseMessage,
  DeleteLinkRequestBody,
  DeleteLinkResponseMessage,
  ProvinceRequestBody,
  ProvinceResponseMessage,
  LeadsContactRequestBody,
  LeadsContactResponseMessage,
  UpdateCopyUrlRequestBody,
  UpdateCopyUrlResponseMessage,
  SharedContentDetailsRequestBody,
  SharedContentDetailsResponseMessage,
} from '@models/identity/content-links';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenerateMsgUtilService } from 'src/app/utils/generate-msg-util.service';
import { environment } from 'src/environments/environment';
import { LeadTemperature, LeadTemperatureColor } from '@models/enum/lead-temperature.enum';
import { LatestCallStatus, LatestCallStatusColor } from '@models/enum/latest-call-status.enum';

const apiURL: string = environment.apiEndpoint;
const apiVersion: string = environment.apiVersion;
@Injectable({
  providedIn: 'root',
})
export class ContentLinksService {
  private httpClient: HttpClient;
  private generateMsgUtil: GenerateMsgUtilService;

  constructor(private injector: Injector) {
    this.httpClient = this.injector.get<HttpClient>(HttpClient);
    this.generateMsgUtil = this.injector.get<GenerateMsgUtilService>(GenerateMsgUtilService);
  }

  getFolders(): Observable<FoldersResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(new FoldersRequestBody());

    return this.httpClient
      .post<FoldersResponseMessage>(
        `${apiURL}/${apiVersion}/resource/content/getgeneratedlinksfolder`,
        requestMsg
      )
      .pipe(map((response) => response));
  }

  getLanguage(): Observable<LanguageRequestBody> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(new LanguageRequestBody());

    return this.httpClient
      .post<LanguageResponseMessage>(`${apiURL}/${apiVersion}/resource/content/getlanguage`, requestMsg)
      .pipe(map((response) => response));
  }

  getCategory(): Observable<CategoriesRequestBody> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(new CategoriesResponseMessage());

    return this.httpClient
      .post<CategoriesResponseMessage>(`${apiURL}/${apiVersion}/resource/content/getcategory`, requestMsg)
      .pipe(map((response) => response));
  }

  getContentLinks(reqBody: ContentLinksRequestBody): Observable<ContentLinksResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<ContentLinksResponseMessage>(
        `${apiURL}/${apiVersion}/resource/content/getgeneratedlinks`,
        requestMsg
      )
      .pipe(map((response) => response));
  }

  getContentDetails(reqBody: ContentDetailsRequestBody): Observable<ContentDetailsResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<ContentDetailsResponseMessage>(
        `${apiURL}/${apiVersion}/resource/content/getcampaigndetail`,
        requestMsg
      )
      .pipe(map((response) => response));
  }

  getSharedContentDetails(reqBody: SharedContentDetailsRequestBody): Observable<SharedContentDetailsResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<SharedContentDetailsResponseMessage>(
        `${apiURL}/${apiVersion}/resource/content/getsharedlinkdetails`,
        requestMsg
      )
      .pipe(map((response) => response));
  }

  updatePersonalMessage(
    reqBody: UpdatePersonalMessageRequestBody
  ): Observable<UpdatePersonalMessageResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<UpdatePersonalMessageResponseMessage>(
        `${apiURL}/${apiVersion}/resource/content/updatepersonalmsg`,
        requestMsg
      )
      .pipe(map((response) => response));
  }

  generateQRCode(reqBody: GenerateQRCodeRequestBody): Observable<GenerateQRCodeResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<GenerateQRCodeResponseMessage>(
        `${apiURL}/${apiVersion}/resource/content/generateqrcode`,
        requestMsg
      )
      .pipe(map((response) => response));
  }

  deleteLink(reqBody: DeleteLinkRequestBody): Observable<DeleteLinkResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<DeleteLinkResponseMessage>(`${apiURL}/${apiVersion}/resource/content/deletelink`, requestMsg)
      .pipe(map((response) => response));
  }

  getProvince(): Observable<ProvinceRequestBody> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(new ProvinceResponseMessage());

    return this.httpClient
      .post<ProvinceResponseMessage>(`${apiURL}/${apiVersion}/customer/getProvince`, requestMsg)
      .pipe(map((response) => response));
  }

  getLeadsContact(reqBody: LeadsContactRequestBody): Observable<LeadsContactResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<LeadsContactResponseMessage>(`${apiURL}/${apiVersion}/customer/GetLeadContacts`, requestMsg)
      .pipe(map((response) => response));
  }

  updateCopyUrlCount(reqBody: UpdateCopyUrlRequestBody): Observable<UpdateCopyUrlResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);

    return this.httpClient
      .post<UpdateCopyUrlResponseMessage>(`${apiURL}/${apiVersion}/resource/content/updatecopyurlcount`, requestMsg)
      .pipe(map((response) => response));
  }

  /**
   * Handle Language Data. Used in View Link History page.
   */
  handleLanguageData(data: any): any[] {
    let componentData = [];

    componentData = data.map((val) => {
      const languages = {
        value: val.no,
        label: val.name,
        check: false,
      };

      return languages;
    });

    return componentData;
  }

  /**
   *  Handle Folders Data. Used in View Link History page.
   */
  handleFoldersData(data: any): any[] {
    let componentData = [];
    let firstFolder = true;

    componentData = data.map((val) => {
      const folders = {
        value: val.folderId,
        title: val.folderName,
        notAllowShare: val.notAllowShare,
        total: val.totalContentCount,
        new: val.totalContentUnreadCount,
        check: firstFolder,
      };

      firstFolder = false;

      return folders;
    });

    return componentData;
  }

  /**
   * Handle Category Data. Used in View Link History page.
   */
  handleCategoryData(data: any): any[] {
    let componentData = [];

    componentData = data.map((val) => {
      const categories = {
        value: val.no,
        label: val.name,
        check: false,
      };

      return categories;
    });

    return componentData;
  }

  /**
   * Handle Contact Data. Used in Leads Contact page.
   */
  handleContactCards(data: any): any[] {
    let componentData = [];

    componentData = data.map((val) => {
      const ccData = {
        name: val.name ?? '-',
        contactId: val.contactId,
        contactType: val.contactType,
        priMobileNo: val.priMobileNo,
        secMobileNo: val.secMobileNo,
        altMobileNo: val.altMobileNo,
        status: [],
        labels: [],
        badges: [],
        statusBadges: [],
      };

      // Status
      if (val.isBirthday) { ccData.status.push('birthday'); }
      if (val.isHighNetWorth) { ccData.status.push('cash'); }
      if (val.isRegisterInAIA) { ccData.status.push('my aia'); }

      // Labels
      if (val.isPolicyOwner) {
        ccData.labels.push({ khmer: 'ម្ចាស់បណ្ណសន្យារ៉ាប់រង', eng: 'Policy owner' });
      }
      if (val.gender) { ccData.labels.push({ khmer: val.gender === 'M' ? 'ប្រុស' : 'ស្រី', eng: val.gender }); }
      if (val.age) { ccData.labels.push({ eng: val.age }); }

      // Badges
      if (val.leadTemperature) {
        ccData.badges.push({
          label: LeadTemperature[val.leadTemperature],
          color: LeadTemperatureColor[val.leadTemperature],
        });
      }

      // Status Badges
      val.statusList.forEach((val2) => {
        if (val2) {
          ccData.statusBadges.push({
            label: LatestCallStatus[val2],
            color: LatestCallStatusColor[val2],
          });
        }
      });

      return ccData;
    });

    return componentData;
  }
}
