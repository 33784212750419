import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalOutletService } from '@modules/modal-outlet/modal-outlet.service';

@Component({
  selector: 'default-form',
  templateUrl: './default-form.component.html',
  styleUrls: ['./default-form.component.scss'],
})
export class DefaultFormComponent implements OnInit {
  form: FormGroup;
  @Output() closeModal = new EventEmitter<any>();
  @Output() openModalPopup = new EventEmitter<any>();

  isReferredDisabled: boolean = false;

  province = [
    {
      label: 'Banteay Meanchey (បន្ទាយមានជ័យ)',
      value: 'Banteay Meanchey',
      status: true,
    },
    {
      label: 'Battambang (បាត់ដំបង)',
      value: 'Battambang',
      status: true,
    },
    {
      label: 'Kampong Cham (កំពង់ចាម)',
      value: 'Kampong Cham',
      status: true,
    },
    {
      label: 'Kampong Chhnang (កំពង់ឆ្នាំង)',
      value: 'Kampong Chhnang',
      status: true,
    },
    {
      label: 'Kampong Speu (កំពង់ស្ពឺ)',
      value: 'Kampong Speu',
      status: true,
    },
    {
      label: 'Kampong Thom (កំពង់ធំ)',
      value: 'Kampong Thom',
      status: true,
    },
    {
      label: 'Kampot (កំពត)',
      value: 'Kampot',
      status: true,
    },
    {
      label: 'Kandal (កណ្ដាល)',
      value: 'Kandal',
      status: true,
    },
    {
      label: 'Kep (កែប)',
      value: 'Kep',
      status: true,
    },
    {
      label: 'Koh Kong (កោះកុង)',
      value: 'Koh Kong',
      status: true,
    },
    {
      label: 'Kratié (ក្រចេះ)',
      value: 'Kratié',
      status: true,
    },
    {
      label: 'Mondulkiri (មណ្ឌលគីរី)',
      value: 'Mondulkiri',
      status: true,
    },
    {
      label: 'Oddar Meanchey (ឧត្តរមានជ័យ)',
      value: 'Oddar Meanchey',
      status: true,
    },
    {
      label: 'Pailin (ប៉ៃលិន)',
      value: 'Pailin',
      status: true,
    },
    {
      label: 'Phnom Penh (ភ្នំពេញ)',
      value: 'Phnom Penh',
      status: true,
    },
    {
      label: 'Preah Sihanouk (ព្រះសីហនុ)',
      value: 'Preah Sihanouk',
      status: true,
    },
    {
      label: 'Preah Vihear (ព្រះវិហារ)',
      value: 'Preah Vihear',
      status: true,
    },
    {
      label: 'Prey Veng (ព្រៃវែង)',
      value: 'Prey Veng',
      status: true,
    },
    {
      label: 'Pursat (ពោធិ៍សាត់)',
      value: 'Pursat',
      status: true,
    },
    {
      label: 'Ratanak Kiri (រតនគីរី)',
      value: 'Ratanak Kiri',
      status: true,
    },
    {
      label: 'Siem Reap (សៀមរាប)',
      value: 'Siem Reap',
      status: true,
    },
    {
      label: 'Steung Treng (ស្ទឹងត្រែង)',
      value: 'Steung Treng',
      status: true,
    },
    {
      label: 'Svay Rieng (ស្វាយរៀង)',
      value: 'Svay Rieng',
      status: true,
    },
    {
      label: 'Takéo (តាកែវ)',
      value: 'Takéo',
      status: true,
    },
    {
      label: 'Tboung Khmum (ត្បូងឃ្មុំ)',
      value: 'Tboung Khmum',
    },
  ];

  constructor(private modalOutletService: ModalOutletService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      mobile_number: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      referred_by: [{ value: '0122222223', disabled: false }, Validators.required],
      province: ['', Validators.required],
    });
  }

  ngOnInit() {}

  onSubmit() {
    if (this.form.valid) {
      // Perform the desired action here, such as sending the form data to a server
      let data = {
        ...this.form.value,
        referred_by: this.form.get('referred_by').value,
      };

      this.modalOutletService.close();
      this.openModalPopup.emit();
    } else {
      // Handle form validation errors
      this.form.markAllAsTouched();
    }
  }

  onCloseModal() {
    this.modalOutletService.close();
    // this.openModalPopup.emit();
  }
}
