import { HTTP } from '@ionic-native/http/ngx';
// Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
// Ionic
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// Project
import { ModalOutletModule } from '@modules/modal-outlet/modal-outlet.module';
import { ModalPopupModule } from '@modules/modal-popup/modal-popup.module';
import { MessageModule } from '@modules/message/message.module';
import { SideTabOutletModule } from '@modules/side-tab-outlet/side-tab-outlet.module';
// Module
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NativeHttpInterceptor } from './utils/native-http-interceptor';

// External
import { CountdownModule } from 'ngx-countdown';
import { SpinnerModule } from '@modules/spinner/spinner.module';
import { DefaultFormModule } from '@modules/default-form/default-form.module';
import { DatePipe } from '@angular/common';
import { OKTA_PROVIDER } from 'src/environments/okta-config';
import { NumberFormat2Pipe } from './pipes/numberFormat2.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'aiaAlpha',
      // eslint-disable-next-line no-underscore-dangle
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB],
    }),
    ModalOutletModule,
    ModalPopupModule,
    MessageModule,
    CountdownModule,
    AppRoutingModule,
    SideTabOutletModule,
    HttpClientModule,
    SpinnerModule,
    DefaultFormModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: NativeHttpInterceptor, multi: true },
    HTTP,
    DatePipe,
    NumberFormat2Pipe,
    OKTA_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}