import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

export type DevicePlatform = 'web' | 'android' | 'ios' | 'huawei';

export type DeviceType = 'web' | 'tablet' | 'mobile';

@Injectable({
  providedIn: 'root',
})
export class PlatformUtilService {
  constructor(private platform: Platform) {}

  /**
   * Returns the current device platform
   */
  get getDevicePlatform(): DevicePlatform {
    if (
      (this.platform.is('desktop') || this.platform.is('mobileweb')) &&
      !this.platform.is('capacitor')
    ) {
      return 'web';
    } else if (this.platform.is('capacitor') && this.platform.is('ios')) {
      return 'ios';
    } else if (this.platform.is('capacitor') && this.platform.is('android')) {
      return 'android';
    }
  }

  /**
   * Returns the device type, either desktop or mobile
   */
  get getDeviceType(): DeviceType {
    // if web else app.
    if (Capacitor.getPlatform() === 'web') {
      return 'web';
    } else {
      if (this.platform.is('tablet') || this.platform.is('ipad')) {
        return 'tablet';
      } else if (this.platform.is('mobile')) {
        return 'mobile';
      }
    }
    return 'mobile';
  }
}