import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultFormComponent } from './default-form.component';
import { ContentWrapperModule } from '@modules/content-wrapper/content-wrapper.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from '@modules/dropdown/dropdown.module';

@NgModule({
  declarations: [DefaultFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ContentWrapperModule,
    DropdownModule,
  ],
  exports: [DefaultFormComponent],
})
export class DefaultFormModule { }
