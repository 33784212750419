import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * This Util class is used to check if the url is a public api
 * 
 * // Public Api doesn't need to call refreshToken api when token expired 
 * // Public Api doesn't need to add bearer token in api request
 */
@Injectable({
  providedIn: 'root',
})
export class PublicApiUtilService {
  constructor() {}

  isPublicApi(url: string): boolean {
    // Remove baseUrl from the url
    var endpoint = url.replace(environment.apiEndpoint, '');

    switch (endpoint) {
      case '/v2/customer/viewAgentProfile':
      case '/v2/auth/login':
      // case '/v2/auth/refreshtoken':
      case '/v2/auth/sendotp':
      case '/v2/auth/verifyotp':
      case '/v2/auth/killSession':

      case '/v2/article/sections':
      case '/v2/article/banners':
      case '/v2/article/highlights':
      case '/v2/article/section/articletags':
      case '/v2/article/section/articlelist':
      case '/v2/article/section/article':
      case '/v2/article/related':
      case '/v2/article/featured':
      case '/v2/article/visit':
      case '/v2/article/like':
      case '/v2/article/unlike':
      case '/v2/article/share':

      case '/v2/resource/content/getlanguage':
      case '/v2/resource/content/getsharedlinkdetails':
      case '/v2/resource/content/notinterested':
      case '/v2/resource/content/interested':
      case '/v2/resource/content/interestedclick':
      case '/v2/resource/content/updatecopyurlcount':
      case '/v2/resource/content/visit':
      case '/v2/resource/content/getlanguage':
      case '/v2/resource/content/getsharedlinkdetails':
      case '/v2/resource/content/notinterested':
      case '/v2/resource/content/interested':
      case '/v2/resource/content/interestedclick':
      case '/v2/resource/content/updatecopyurlcount':
      case '/v2/resource/content/visit':
      
      case '/v2/general/getAreaCode':
      case '/v2/customer/GetProvince':
      case '/v2/customer/submitcontact':
      case '/v2/customer/uploadMediaByByte':
      case '/v2/customer/addappointment':
      case '/v2/general/getAreaCode':
      case '/v2/customer/GetProvince':
      case '/v2/customer/submitcontact':
      case '/v2/customer/uploadMediaByByte':
      case '/v2/customer/addappointment':
        return true;
      default:
        return false;
    }
  }
}
