import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  imageFolder: string = 'assets/ui/icons/'
  showMsg: boolean = false;

  constructor() { }

  ngOnInit() {}

  show() {
    this.showMsg = true;
  }

  close() {
    this.showMsg = false;
  }
}
