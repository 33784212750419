// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Module
import { ModalOutletComponent } from './modal-outlet.component';

@NgModule({
  declarations: [ModalOutletComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ModalOutletComponent
  ]
})
export class ModalOutletModule { }
