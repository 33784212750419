// Base
import { BaseRequestBody, BaseRequestMessage } from '@models/base-request';
import { BaseResponseBody, BaseResponseMessage } from '../base-response';

import { FilterByAge, FilterByGender, FilterByPolicy } from '@models/enum/common-list-filters.enum';
import {
  FilterByBirthday,
  FilterByCrossSelling,
  FilterByLeadStatus,
  FilterByNoOfPolicy,
  SortBy,
} from '@models/enum/contact-list-filters.enum';
import { CertificationUpdateInfoObj } from '@models/common/certification-update-info-obj';
import { ImageInfoObj, ImageObj } from '@models/common/image-obj';
import { BadgesUpdateInfoObj } from '@models/common/badges-update-info-obj';

// Objects

/**
 * ========== FOLDERS API ==========
 */
export class FoldersRequestBody extends BaseRequestBody {}

export class FoldersRequestMessage extends BaseRequestMessage<FoldersRequestBody> {}

export class FoldersResponseBody extends BaseResponseBody {
  contentFolderList: [];
  totalCount: number;
}

export class FoldersResponseMessage extends BaseResponseMessage<FoldersResponseBody> {}

/**
 * ========== LANGUAGE API ==========
 */
export class LanguageRequestBody extends BaseRequestBody {}

export class LanguageRequestMessage extends BaseRequestMessage<LanguageRequestBody> {}

export class LanguageResponseBody extends BaseResponseBody {
  no: number;
  name: string;
  status: string;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
}

export class LanguageResponseMessage extends BaseResponseMessage<LanguageResponseBody> {}

/**
 * ========== CATEGORIES API ==========
 */
export class CategoriesRequestBody extends BaseRequestBody {}

export class CategoriesRequestMessage extends BaseRequestMessage<CategoriesRequestBody> {}

export class CategoriesResponseBody extends BaseResponseBody {
  categoryList: [];
}

export class CategoriesResponseMessage extends BaseResponseMessage<CategoriesResponseBody> {}

/**
 * ========== GENERATED CONTENT LINKS API ==========
 */
export class ContentLinksRequestBody extends BaseRequestBody {
  attachmentType?: [];
  categoryNo?: [];
  contentName?: string;
  pageIndex: number;
  dateFrom?: string;
  dateTo?: string;
  folderId?: string;
  languageNo?: string[];
  pageSize: number;
  sortBy?: string;
}

export class ContentLinksRequestMessage extends BaseRequestMessage<ContentLinksRequestBody> {}

export class ContentLinksResponseBody extends BaseResponseBody {
  contentLinkList: [];
  totalCount: number;
}

export class ContentLinksResponseMessage extends BaseResponseMessage<ContentLinksResponseBody> {}

/**
 * ========== CONTENT DETAILS API ==========
 */
export class ContentDetailsRequestBody extends BaseRequestBody {
  resourceNo: number;
}

export class ContentDetailsRequestMessage extends BaseRequestMessage<ContentDetailsRequestBody> {}

export class ContentDetailsResponseBody extends BaseResponseBody {
  articleCoverImage: string;
  articleText: string;
  attachmentUrl: string;
  contentCreatedDate: string;
  contentDesc: string;
  contentId: string;
  contentName: string;
  contentNo: number;
  coverImageUrl: string;
  coverImg: string;
  defaultInterestButtonText: string;
  defaultKnowMoreButtonText: string;
  defaultLikeButtonText: string;
  defaultNotInterestButtonText: string;
  defaultPersonalMsg: string;
  defaultShareContentButtonText: string;
  isAllowToShare: boolean;
  isArticle: boolean;
  isContentAllowToShare: boolean;
  maxLead: number;
  personalMsg: string;
  resourceCreatedDate: string;
  resourceId: string;
  thumbnailImageUrl: string;
  totalInterest: number;
  totalNoResponse: number;
  totalNotInterest: number;
  totalView: number;
  url: string;
}

export class ContentDetailsResponseMessage extends BaseResponseMessage<ContentDetailsResponseBody> {}

/**
 * ========== SHAREDD CONTENT DETAILS API ==========
 */
export class SharedContentDetailsRequestBody extends BaseRequestBody {
  resourceNo: number;
  session: string;
}

export class SharedContentDetailsRequestMessage extends BaseRequestMessage<SharedContentDetailsRequestBody> {}

export class SharedContentDetailsResponseBody extends BaseResponseBody {
  url: string;
  title: string;
  attachmentUrl: string;
  attachmentUrl2: string;
  attachmentUrl3: string;
  attachmentUrl4: string;
  attachmentUrl5: string;
  attachmentType: number;
  coverImageUrl: string;
  thumbnailImageUrl: string;
  personalMsg: string;
  interestedText: string;
  notInterestedText: string;
  whatsAppMsg: string;
  hasVisited: boolean;
  response: boolean;
  isAdmin: boolean;
  isFhc: boolean;
  fhcbuttonText: string;
  fhclink: string;
  isArticle: boolean;
  isAllowToShare: boolean;
  articleCoverImage: string;
  articleText: string;
  resourceId: string;
  cropCoverImageFacebook: string;
  enableChildForm: boolean;
  maxLead: number;
  likeButtonText: string;
  knowMoreButtonText: string;
  shareContentButtonText: string;
  totalLike: number;
  knowMoreContact: string;
  knowMoreMsg: string;
  contentId: string;
  contentNo: number;
  interestedRedirectedLink: string;
  notInterestedRedirectedLink: string;
  isAllowAppointment: boolean;
  leadFormID: string;
  leadFormTitle: string;
  leadFormDescription: string;
  leadFormURL: string;
  leadFormPrice: number;
  leadFormData: string;
  isDirectLeadForm: boolean;
  agentDetail: {
    agentCode: string;
    agentName: string;
    agentRank: string;
    agencyName: string;
    agentIdNo: string;
    agentLicense: string;
    dateJoined: string;
    uplineManager: string;
    region: string;
    serviceBranch: string;
    RSMName: string;
    correspondenceAdd: string;
    priMobileNo: string;
    secMobileNo: string;
    altMobileNo: string;
    tenure: string;
    agentEmail: string;
    layoutNo: string;
    isProfilePicturePublic: boolean;
    isPersonalDetailsPublic: boolean;
    isProfSummaryPublic: boolean;
    certPublicList: CertificationUpdateInfoObj[];
    isMdrtAchievPublic: boolean;
    isLatestAchievPublic: boolean;
    isTeamPhotoPublic: boolean;
    profSumTitle: string;
    profSumDesc: string;
    profileImage: ImageInfoObj;
    teamPhoto1: ImageObj;
    teamPhoto2: ImageObj;
    teamPhoto3: ImageObj;
    certificationList: BadgesUpdateInfoObj[];
    latestbadgesAndtrophyList: BadgesUpdateInfoObj[];
    mdrtBadgeList: ImageInfoObj[];
    agencyLogo: ImageInfoObj;
    mdrtLogo: ImageInfoObj;
    hireDate: string;
  };
}

export class SharedContentDetailsResponseMessage extends BaseResponseMessage<SharedContentDetailsResponseBody> {}

/**
 * ========== UPDATE PERSONAL MESSAGE API ==========
 */
export class UpdatePersonalMessageRequestBody extends BaseRequestBody {
  resourceNo: number;
  personalMsg: string;
}

export class UpdatePersonalMessageRequestMessage extends BaseRequestMessage<UpdatePersonalMessageRequestBody> {}

export class UpdatePersonalMessageResponseBody extends BaseResponseBody {}

export class UpdatePersonalMessageResponseMessage extends BaseResponseMessage<UpdatePersonalMessageResponseBody> {}

/**
 * ========== GENERATE QR CODE API ==========
 */
export class GenerateQRCodeRequestBody extends BaseRequestBody {
  resourceNo: number;
  coverImageUrl: string;
}

export class GenerateQRCodeRequestMessage extends BaseRequestMessage<GenerateQRCodeRequestBody> {}

export class GenerateQRCodeResponseBody extends BaseResponseBody {
  bytePDF: string;
}

export class GenerateQRCodeResponseMessage extends BaseResponseMessage<GenerateQRCodeResponseBody> {}

/**
 * ========== DELETE LINK API ==========
 */
export class DeleteLinkRequestBody extends BaseRequestBody {
  resourceNo: number;
}

export class DeleteLinkRequestMessage extends BaseRequestMessage<DeleteLinkRequestBody> {}

export class DeleteLinkResponseBody extends BaseResponseBody {}

export class DeleteLinkResponseMessage extends BaseResponseMessage<DeleteLinkResponseBody> {}

/**
 * ========== PROVINCE API ==========
 */
export class ProvinceRequestBody extends BaseRequestBody {}

export class ProvinceRequestMessage extends BaseRequestMessage<ProvinceRequestBody> {}

export class ProvinceResponseBody extends BaseResponseBody {}

export class ProvinceResponseMessage extends BaseResponseMessage<ProvinceResponseBody> {}

/**
 * ========== LEADS CONTACT API ==========
 */
export class LeadsContactRequestBody extends BaseRequestBody {
  filterByCustomerStatus: string;
  filterByName: string;
  filterByResourceNo: string;
  startDate: string;
  endDate: string;
  sortByName: string;
  pageIndex: number;
  pageSize: number;
}

export class LeadsContactRequestMessage extends BaseRequestMessage<LeadsContactRequestBody> {}

export class LeadsContactResponseBody extends BaseResponseBody {
  contactList: [];
  totalCount: number;
}

export class LeadsContactResponseMessage extends BaseResponseMessage<LeadsContactResponseBody> {}

/**
 * ========== UPDATE COPY URL API ==========
 */
export class UpdateCopyUrlRequestBody extends BaseRequestBody {
  resourceId: string;
}

export class UpdateCopyUrlRequestMessage extends BaseRequestMessage<UpdateCopyUrlRequestBody> {}

export class UpdateCopyUrlResponseBody extends BaseResponseBody {}

export class UpdateCopyUrlResponseMessage extends BaseResponseMessage<UpdateCopyUrlResponseBody> {}
