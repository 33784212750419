/* eslint-disable no-underscore-dangle */
// Angular
import { Injectable, Injector } from '@angular/core';
// Ionic
import { Storage } from '@ionic/storage-angular';
// Module
import { environment } from 'src/environments/environment';
//CryptoJS plugin
import * as AES from 'crypto-js/aes';
import * as utf8 from 'crypto-js/enc-utf8';
//Plugins
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
  providedIn: 'root',
})
export class SecureStorageService {
  private _storage: Storage | null = null;
  private storage: Storage;

  constructor(private injector: Injector) {
    this.storage = this.injector.get<Storage>(Storage);
    this.init();
  }

  public async init() {
    if (this._storage) {
      return;
    }
    await this.storage.defineDriver(CordovaSQLiteDriver).catch((e) => console.error(e));
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public set(key: string, value: any): void {
    this._storage?.set(key, this.encrypt(value));
  }

  public remove(key: string): void {
    this._storage?.remove(key);
  }

  public get(key: string): Promise<any> {
    //check whether my storage
    return this.decrypt(key);
  }

  private encrypt(data: any): string {
    return AES.encrypt(JSON.stringify(data), environment.storeKey).toString();
    // return data;
  }

  private async decrypt(key: string): Promise<any> {
    // const encryptText = await this._storage?.get(key);
    // return encryptText;
    const encryptText = await this._storage?.get(key);
    if (encryptText == null) {
      return null;
    } else {
      const bytes = AES.decrypt(encryptText, environment.storeKey);
      return JSON.parse(bytes.toString(utf8));
    }
  }
}
