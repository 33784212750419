// Angular
import { Injectable, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
// Module

@Injectable({
  providedIn: 'root',
})
export class ModalOutletService {
  templateHistory: (TemplateRef<any> | undefined)[] = [undefined];
  templateList: { name: string, template: TemplateRef<any> }[] = [];
  currentHistoryIndex: number = -1;
  full: boolean = false;

  constructor(private location: Location) {}

  get modalTemplate(): TemplateRef<any> | undefined {
    return this.templateHistory[this.currentHistoryIndex];
  }

  showModal(modalTemplate: TemplateRef<any>, full?: string): void {
    this.templateHistory = [
      ...this.templateHistory.slice(0, this.currentHistoryIndex + 1),
      modalTemplate,
    ];

    if (full === 'full') {
      this.full = true;
    } else {
      this.full = false;
    }

    this.currentHistoryIndex = this.templateHistory.length - 1;
    this.pushHistoryState();
  }

  close() {
    this.currentHistoryIndex = -1;
  }

  goToHistoryIndex(targetIndex: number): void {
    this.currentHistoryIndex = targetIndex;
  }

  previousHistory(): void {
    this.currentHistoryIndex--;
  }

  nextHistory(): void {
    this.currentHistoryIndex++;
  }

  pushHistoryState(): void {
    let url: string = this.location.path();

    this.location.replaceState(url, '', {
      modalOutletHistoryIndex: this.currentHistoryIndex - 1,
    });
  }

  openGlobalModal(name: string) {
    const selectedTempelate = this.templateList.find(e => e.name === name)

    if (selectedTempelate) {
      this.showModal(selectedTempelate.template);
    } else {
      console.error('Invalid Template?')
    }
  }
}
