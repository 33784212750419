/* eslint-disable @typescript-eslint/naming-convention */
export enum LatestCallStatus {
  LEADASSIGNEDBYAIA = 'Lead Assigned by AIA',
  APPLICATIONINPROGRESS = 'Application in Progress',
  SELFCREATEDCONTACT = 'Self created contact',
  NOTINTERESTED = 'Not interested',
  NOREASON = 'Not interested - No reason specified',
  NOTAFFORD = 'Not interested - Cannot afford',
  HASAIAPOLICY = 'Not interested - Has policy with AIA',
  HASOTHERPOLICY = 'Not interested - Has policy with another company',
  SPAUSEDISAGREE = 'Not interested - spouse does not agree',
  INTERESTED = 'Interested',
  CONVERTED = 'Converted',
  APPOINTMENTSET = 'Appointment set',
  SCHEDULECALLBACK = 'Scheduled call back',
  NOTCONTACTABLE = 'Not contactable',
  WRONGNUMBER = 'Wrong number',
  CUSTWPAY = 'Customer willing to Pay/Reinstate',
  CUSTWPAY_NOTAFFORD = 'Customer will not Pay/Reinstate - Cannot afford',
  CUSTWPAY_OTHERREASON = 'Customer will not Pay/Reinstate - Other Reason',
}

export enum LatestCallStatusColor {
  LEADASSIGNEDBYAIA = 'mono 20',
  APPLICATIONINPROGRESS = 'orange 25',
  SELFCREATEDCONTACT = 'mono 20',
  NOTINTERESTED = 'warm grey',
  NOREASON = 'warm grey',
  NOTAFFORD = 'warm grey',
  HASAIAPOLICY = 'warm grey',
  HASOTHERPOLICY = 'warm grey',
  SPAUSEDISAGREE = 'warm grey',
  INTERESTED = 'green',
  CONVERTED = 'green',
  APPOINTMENTSET = 'mono 20',
  SCHEDULECALLBACK = 'mono 20',
  NOTCONTACTABLE = 'warm grey',
  WRONGNUMBER = 'warm grey',
  CUSTWPAY = 'mono 20',
  CUSTWPAY_NOTAFFORD = 'mono 20',
  CUSTWPAY_OTHERREASON = 'mono 20',
}
