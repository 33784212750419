// Angular
import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ModalOutletService } from '@modules/modal-outlet/modal-outlet.service';
// Component

@Component({
  selector: 'modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
})
export class ModalPopupComponent implements OnInit {
  @ViewChild('popupRoot') popupRoot: ElementRef;
  @Input('type') type: any = 'default';
  @Input('closeBtn') closeBtn: boolean = false;
  @Input('outsideCloseEnabled') outsideCloseEnabled: boolean = true;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  imageFolder: string = 'assets/content/modules/modal-popup/';

  constructor(
    private location: Location,
    private router: Router,
    private modalOutletService: ModalOutletService
  ) {}

  ngOnInit() {}

  handlePopupRootClick(event: MouseEvent): void {
    if (event.target === this.popupRoot.nativeElement && this.outsideCloseEnabled) {
      this.modalOutletService.previousHistory();
      this.closeModal.emit();
    }
  }

  close() {
    this.modalOutletService.previousHistory();
    this.closeModal.emit();
  }

  goToLogin() {
    this.location.back();
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 10);
  }
}
1;
