import { trigger, style, transition, animate } from '@angular/animations';

export const SlideDown = trigger('slideDown', [
  transition(':enter', [
    style({
      height: 0
    }),
    animate(
      `0.4s cubic-bezier(0.19, 1, 0.22, 1)`,
      style({
        height: '*'
      })
    ),
  ]),
  transition(':leave', [
    style({
      height: '*'
    }),
    animate(
      `0.4s cubic-bezier(0.19, 1, 0.22, 1)`,
      style({
        height: 0
      })
    ),
  ]),
]);
