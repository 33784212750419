import {
  animate,
  animateChild,
  group,
  query,
  transition,
  trigger,
  style,
} from '@angular/animations';
import { EaseOutExpo } from './css-easings';

let durationInSeconds: number = 0.4;
let easing: string = EaseOutExpo;
let animationStart: any = {
  opacity: 0,
  transform: 'scale(1.1)',
};
let animationEnd: any = {
  opacity: 1,
  transform: 'scale(1)',
};

export const FadeAndScaleIn = trigger('fadeAndScaleIn', [
  transition(':enter', [
    style(animationStart),
    group([
      animate(`${durationInSeconds}s ${easing}`, style(animationEnd)),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
  transition(':leave', [
    style(animationEnd),
    group([
      animate(`${durationInSeconds}s ${easing}`, style(animationStart)),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
]);
