// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  isMockApi: false, // true to use static mock data for all api service
  isShowQuickNav: false, // true to show quick navigation in Login screen
  mockApiDelay: 1000, // mock api delay in millisecond

  mockData: false, // true to use static mock data for all API services
  mockAutoLogin: false, // true to simulate that user is always logged in
  mockError: false,
  apiEndpoint: 'https://alpaapiuat.aia.com.kh',
  apiVersion: 'v2',
  storeKey: '3BF164E4337FB8BCF7DC869BA5DE9',
  createQuotationUrl: 'https://www2uat.aia.com.kh/revampIpos/#/quotation_cards',
  applicationUrl: 'https://www2uat.aia.com.kh/webipos/#/application',
  generateNewLeadsUrl: 'https://cthubuat.aia.com.kh/contenthub/resource-centre/content-link/new',
  viewLinkHistoryUrl: 'https://cthubuat.aia.com.kh/contenthub/resource-centre/my-activities',
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.