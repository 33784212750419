import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiErrorHandlerService {

  error = new BehaviorSubject<any> (null);

  constructor() { }

  setError(error: any) {
    this.error.next(error);
  }
}
