/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Generic ResultCode from api response
 */
export enum ResultCode {
  SUCCESS = '001',
  FIRST_TIME_LOGIN = '002',
  UPDATE_NID = '003',
  INVALID_CREDENTIAL = '200',
  GENERAL_ERROR = '200',
  INVALID_TOKEN = '201',
  INVALID_OTP = '201',
  OTP_EXPIRED = '202',
  OTP_VERIFIED = '203',
  AGENT_NOT_FOUND = '250',
  AGENT_TERMINATED = '251',
  INVALID_MODE = '251',
  AGENT_SUSPENDED = '252',
  AGENT_MOBILE_NOT_FOUND = '252',
  ACCOUNT_LOCKED = '253',
  REQ_PAY_TOO_FREQ = '297'
}

export enum StatusCode {
  Success = '00000',
}

export enum StorageKey {
  AccessToken = 'alpaac',
  RefreshToken = 'alpash',
  TokenExpiry = 'alpate',
  AgentCode = 'alpaac2',
  AgentRankGroup = 'agentrankgroup',

  OAccessToken = 'oktaac',
  ORefreshToken = 'oktash',

  UserAccounts = 'ua',
  BiometricKeys = 'bk',
  PinKeys = 'pk',
}

// above all are in used

export enum StorageKeyOLD {
  SessionTokens = 'st',
  AccessToken = 'ac',
  TwofaToken = 'tt',
  RefreshToken = 'sh',
  UserAccounts = 'ua',
  BiometricKeys = 'bk',
  ContactsFilter = 'cf',
  ContactsSort = 'cs',
  ServicesSort = 'ss',
  ServicesFilter = 'sf',
  ClaimsIlSort = 'clils',
  ClaimsIlFilter = 'clilf',
  ClaimsGlSort = 'clgls',
  ClaimsGlFilter = 'clglf',
  NewBusinessSort = 'nbs',
  NewBusinessFilter = 'nbf',
  PoliciesSort = 'ps',
  PoliciesFilter = 'pf',
}

export enum Workflow {
  FTLSentOTP = 'FTL_SENT_OTP',
  FTLVerifyOTP = 'FTL_VERIFY_OTP',
  FTLSetPin = 'FTL_SET_PIN',
  FTLSetBio = 'FTL_SET_BIO',
  FTLTerm = 'FTL_TERM',
  VerifyPin = 'VERIFY_PIN',
  VerifyOTP = 'VERIFY_OTP',
  VerifyUserPwd = 'VERIFY_USER_PWD',
  SentOTP = 'SENT_OTP',
  UpdatePinVerifyUserPwd = 'UPDATE_PIN_VERIFY_USER_PWD',
  UpdatePin = 'UPDATE_PIN',
  Dashboard = 'DASHBOARD',
  ChangePinSentOTP = 'CHANGE_PIN_SENT_OTP',
  ChangePinVerifyOTP = 'CHANGE_PIN_VERIFY_OTP',
}

export enum ApiStatus {
  Success = 'success',
  Fail = 'fail',
}

export enum ErrorCode {
  InvalidUsername = '1',
  InvalidUsernamePassword = '0001',
  UserAccountDisabled = '0002',
  PasswordRetryLimit = '0003',
  DuplicateLogin = '0004' /** IN USE */,
  RepeatNumberPIN = '0005',
  AlreadySetupPin = '0006',
  InvalidPIN = '0007' /** IN USE */,
  InvalidOTP = '0008',
  UnableToGetOTP = '0009',
  UploadImageFailed = '0010',
  AccessTokenExpired = '0011' /** IN USE */,
  SessionTokenExpired = '0013' /** IN USE */,
  OtpAttemptsExceeded = '0015' /** IN USE */,
  DataNotFound = '9994',
  FileNotFound = '9996',
  UnknownErrorOccured = '9998',
}

export enum PopupScreenName {
  PoliciesDetailsContacts = '/policies/details/contacts',
  PoliciesDetailsPaymentHistory = '/policies/details/payment-history',
  PoliciesDetailsAutoPayTransactions = '/policies/details/auto-pay-transactions',
  PoliciesDetailsPolicyStatement = '/policies/details/policy-statement',
  PoliciesDetailsEDocument = '/policies/details/e-document',
}

export enum TooltipsCategory {
  contact = 'CONTACT',
  performance = 'PERFORMANCE',
  newBusiness = 'NEW_BUSINESS',
  policies = 'POLICIES',
  services = 'SERVICES',
  claimIl = 'CLAIM_IL',
  claimGl = 'CLAIM_GL',
  notification = 'NOTIFICATION',
  globalSearch = 'GLOBAL_SEARCH',
}

export enum TooltipsType {
  dashboard = 'DASHBOARD',
  listAll = 'LIST_ALL',
  listLead = 'LIST_LEAD',
  listPotential = 'LIST_POTENTIAL',
  clientDetail = 'CLIENT_DETAIL',
  policy = 'POLICY',
  campaign = 'CAMPAIGN',
  rankingDashboard = 'RANKING_DASHBOARD',
  list = 'LIST',
  tat = 'TAT',
  policies = 'POLICIES',
  policiesDetail = 'POLICIES_DETAIL',
  ilList = 'IL_LIST',
  ilTat = 'IL_TAT',
  glList = 'GL_LIST',
  glTat = 'GL_TAT',
  contactList = 'CONTACT_LIST',
  policyList = 'POLICY_LIST',
  notificationList = 'NOTIFICATION_LIST',
}

export enum ModuleName {
  Dashboard = 'Dashboard',
  Policies = 'Policies',
  Claims = 'Claims',
  Services = 'Services',
  NewBusiness = 'New Business',
  Contacts = 'Contacts',
}

export enum SubModuleName {
  PoliciesGroup = 'Policy Group',
  Notifications = 'Notifications',
  Performance = 'Performance',
  ServicesGroups = 'Service Groups',
  NewBusinessGroup = 'New Business Group',
  AllContacts = 'All Contacts',
  Potential = 'Potential',
}

export enum ModuleTnTType {
  ilClaim = 'IL_CLAIM',
  glClaim = 'GL_CLAIM',
  service = 'SERVICE',
  newBusiness = 'NEW_BUSINESS',
}

export enum ServiceType {
  ALL = 'ALL',
  PSF = 'PSF',
  PSNF = 'PSNF',
  PB = 'PB',
}

export enum ModuleType {
  ilClaim = 'ILC',
  glClaim = 'GLC',
  service = 'S',
  newBusiness = 'NB',
}

export enum SocialNetwork {
  Facebook = 'facebook',
  X = 'X',
  Whatsapp = 'whatsapp',
  Messenger = 'messenger',
  Wechat = 'wechat',
  Telegram = 'telegram',
  SMS = 'sms',
  Email = 'email',
  LinkedIn = 'linkedin',
}

export enum ActiveTab {
  email = 'Email',
  mobileNo = "Mobile no."
}

export enum GroupType {
  District = 'DISTRICT',
  Direct = 'DIRECT'
}