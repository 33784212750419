import {
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KillSessionResponseMessage, RefreshOktaTokenResponseMessage } from '@models/identity/authentication';
import { ValidateSsoKeyResponseMessage } from '@models/identity/sso-key';
import { ModalOutletService } from '@modules/modal-outlet/modal-outlet.service';
import { LoginService } from '@services/api/login.service';
import { IdleTimerService } from '@services/idle-timer.service';
import { ApiErrorHandlerService } from '@services/api/api-error-handler.service';
import { CountdownComponent } from 'ngx-countdown';
import { take } from 'rxjs/operators';
import { ResultCode, StorageKey } from './helpers/enum';
import { AuthStore } from './stores/auth.store';
import { UserStore } from './stores/user.store';
import { PlatformUtilService } from './utils/platform-util.service';
import { LoadingUtilService } from './utils/loading-util.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Meta } from '@angular/platform-browser';
import { ContentLinksService } from '@services/api/content-links.service';
import { SessionService } from '@services/session.service';
import { SecureStorageService } from '@services/storage/secure-storage.service';
import { OktaAuthService } from '@services/okta/okta-auth-service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('idleModal') idleModal: TemplateRef<any>;
  @ViewChild('idleModalError') idleModalError: any;
  @ViewChild('counter') counter: CountdownComponent;
  @ViewChild('errorModal', { static: true }) errorModalRef: TemplateRef<any>;
  @ViewChild('unauthorizedErrorModal') unauthorizedErrorModalRef: TemplateRef<any>;
  @ViewChild('sessionExpiredErrorModal') sessionExpiredErrorModalRef: TemplateRef<any>;

  logoutTimer = 30; // by seconds
  errorMsgKH: string;
  errorMsgEN: string;

  isLoading = false;
  hasRefreshOktaToken = false;

  private secureStorageService: SecureStorageService;
  private userStore: UserStore;
  private loginService: LoginService;
  private modalOutletService: ModalOutletService;
  private router: Router;
  private idleTimer: IdleTimerService;
  private platformUtil: PlatformUtilService;
  private routes: ActivatedRoute;
  private authStore: AuthStore;
  private apiErrorHandlerService: ApiErrorHandlerService;
  private loadingUtil: LoadingUtilService;
  private changeDetector: ChangeDetectorRef;
  private meta: Meta;
  private route: ActivatedRoute;
  private sessionService: SessionService;
  private contentLinksService: ContentLinksService;
  // private meta: Meta;
  // private route: ActivatedRoute;
  // private sessionService: SessionService;
  // private contentLinksService: ContentLinksService;

  constructor(private injector: Injector, private oktaAuthService: OktaAuthService) {
    this.secureStorageService = this.injector.get<SecureStorageService>(SecureStorageService);
    this.userStore = this.injector.get<UserStore>(UserStore);
    this.loginService = this.injector.get<LoginService>(LoginService);
    this.modalOutletService = this.injector.get<ModalOutletService>(ModalOutletService);
    this.router = this.injector.get<Router>(Router);
    this.idleTimer = this.injector.get<IdleTimerService>(IdleTimerService);
    this.platformUtil = this.injector.get<PlatformUtilService>(PlatformUtilService);
    this.routes = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.authStore = this.injector.get<AuthStore>(AuthStore);
    this.apiErrorHandlerService = this.injector.get<ApiErrorHandlerService>(ApiErrorHandlerService);
    this.loadingUtil = this.injector.get<LoadingUtilService>(LoadingUtilService);
    this.changeDetector = this.injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this.meta = this.injector.get<Meta>(Meta);
    this.route = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.contentLinksService = this.injector.get<ContentLinksService>(ContentLinksService);
    this.sessionService = this.injector.get<SessionService>(SessionService);
  }

  async ngOnInit() {

    // Responsible for Loading indicator
    this.loadingUtil.loadingCount.subscribe((count) => {
      // if loading count not equal zero
      this.isLoading = count !== 0;
      this.changeDetector.detectChanges();
    });

    this.apiErrorHandlerService.error.subscribe((error) => {
      if (error != null) {
        console.error(error);
        console.log(error);
        // Unauthorized error 401 will show a dialog with a button, kicking user out once logout is clicked
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (error instanceof HttpErrorResponse && error.error.headerInfo.resultcode === "103") {
            console.log("this.continueSession")
            this.continueSession(true)
          }
          this.errorMsgKH = 'មាននរណាម្នាក់បានចូលនៅលើទំព័រផ្សេងទៀត។ សូមចូលម្តងទៀត';
          this.errorMsgEN = 'Someone has logged in on other pages. Please login again.';
          this.modalOutletService.showModal(this.unauthorizedErrorModalRef);
        } else {
          error = new HttpErrorResponse(error);
          this.errorMsgKH = '';
          this.errorMsgEN = error.message ?? 'Unknown Error. (Please report this error to the developer)';
          if (error.url !== `${environment.apiEndpoint}/${environment.apiVersion}/auth/killSession`) {
            this.modalOutletService.showModal(this.errorModalRef);
          }
        }
      }
    });

    // Initialize SecureStorage
    await this.secureStorageService.init();
    if (this.platformUtil.getDeviceType === 'web') {
      this.redirectSsoKey();
    }

    // Initialize Idle Timer
    this.idleTimer.start(this.idleModal, 900000);

    this.loginService.getAgentCode().then((agentCode) => {
      this.userStore.state.agentCode = agentCode
    })
  }

  // snsSource: string;

  redirectSsoKey() {
    this.routes.queryParams.subscribe((response) => {
      if (response.ssoKey !== '' && response.ssoKey !== undefined) {
        this.loadingUtil.startLoading();
        this.loginService
          .validateSsoKey({
            ssoKey: response.ssoKey,
          })
          .pipe(take(1))
          .subscribe({
            next: (res: ValidateSsoKeyResponseMessage) => {
              switch (res.headerInfo.resultcode) {
                case ResultCode.SUCCESS: {
                  this.authStore.clearAuth();
                  this.secureStorageService.set(StorageKey.AccessToken, res.body.accessToken);
                  this.secureStorageService.set(StorageKey.RefreshToken, res.body.refreshAccessToken);
                  this.secureStorageService.set(StorageKey.TokenExpiry, res.body.expires);
                  this.userStore.setState({
                    ...this.userStore.state,
                    accessToken: res.body.accessToken,
                    refreshAccessToken: res.body.refreshAccessToken,
                    tokenExpiresIn: res.body.expires,
                    agentCode: res.body.agentCode,
                  });
                  this.router.navigate([response.reroute]);
                  break;
                }
                default: {
                  this.loginService.clearStorage();
                  this.router.navigate(['/login']);
                  break;
                }
              }
            },
            error: (err) => console.error(err),
          })
          .add(() => this.loadingUtil.stopLoading());
      }
    });
  }

  continueSession(isAutoRefresh: boolean = false) {
    this.idleModalError.close();

    this.loadingUtil.startLoading();

    this.loginService
      .refreshToken()
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          if (res.headerInfo.result === 1) {
            this.modalOutletService.close();
          } else {
            if (isAutoRefresh) {
              this.errorMsgKH = 'មាននរណាម្នាក់បានចូលនៅលើទំព័រផ្សេងទៀត។ សូមចូលម្តងទៀត';
              this.errorMsgEN = 'Someone has logged in on other pages. Please login again.';
              this.modalOutletService.showModal(this.unauthorizedErrorModalRef);
            } else {
              if (this.refreshOktaToken) {
                this.idleModalError.show();
              } else {
                this.hasRefreshOktaToken = true;
                this.refreshOktaToken();
              }
            }
          }
        },
        error: (err) => console.error(err),
      })
      .add(() => this.loadingUtil.stopLoading());
  }

  logout() {
    this.modalOutletService.close();

    this.loadingUtil.startLoading();

    // need delay cause' it won't logout probably.
    setTimeout(async () => {
      this.loginService
        .killSession({
          agentCode: await this.secureStorageService.get(StorageKey.AgentCode),
          type: 3,
        })
        .pipe(take(1))
        .subscribe({
          next: (res: KillSessionResponseMessage) => {
            this.oktaAuthService.logout();
          },
          error: (err) => console.error(err),
        })
        .add(() => this.loadingUtil.stopLoading());
    }, 10);
  }

  kickUserBackToLogin() {
    // Clear all data
    this.secureStorageService.remove(StorageKey.AccessToken);
    this.secureStorageService.remove(StorageKey.RefreshToken);
    this.secureStorageService.remove(StorageKey.TokenExpiry);
    this.secureStorageService.remove(StorageKey.OAccessToken);
    this.secureStorageService.remove(StorageKey.ORefreshToken);
    this.userStore.clearUser();

    this.modalOutletService.close();

    // For some weird reason, need to add this so we can route to login successfully
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 10);
  }

  refreshOktaToken() {
    this.loginService
      .refreshOktaToken()
      .pipe(take(1))
      .subscribe({
        next: (res: RefreshOktaTokenResponseMessage) => {
          if (res.headerInfo.resultcode === ResultCode.SUCCESS) {
            this.oktaAuthService.appAuthStateSub$.value.authState.accessToken.accessToken = res.body.access_token;
            this.oktaAuthService.appAuthStateSub$.value.authState.accessToken.expiresAt = res.body.expiresAt;
            this.oktaAuthService.appAuthStateSub$.value.authState.refreshToken.refreshToken = res.body.refresh_token;
            this.oktaAuthService.appAuthStateSub$.value.authState.refreshToken.expiresAt = res.body.expiresAt;
            this.oktaAuthService.appAuthStateSub$.value.authState.idToken.idToken = res.body.access_token;
            this.oktaAuthService.appAuthStateSub$.value.authState.idToken.expiresAt = res.body.expiresAt;
            this.continueSession();
          } else {
            this.idleModalError.show();
          }
        },
        error: (err) => console.error(err),
      });
  }
}
