import { PolicyDashboardMockData } from 'src/app/stores/policy-dashboard.store';
import { environment } from 'src/environments/environment';

// General json
import getAreaCode from '../api/general/get-area-code-dropdown.json';

//login module
import userLogin from '../api/user/login.json';
import refreshToken from '../api/user/refreshToken.json';
import sendOtp from '../api/user/sendOtp.json';
import verifyOtp from '../api/user/verifyOtp.json';
import appVersion from '../api/app-version/version.json';
import policyDashboard from '../api/policy/dashboard.json';
import policyListing from '../api/policy/listing.json';
import newBusinessDashboard from '../api/new-business/dashboard.json';
import newBusinessListing from '../api/new-business/listing.json';
import killSession from '../api/user/kill-session.json';
import generateToken from '../api/user/generateToken.json';
import refreshOktaToken from '../api/user/refreshOktaToken.json';

// Policy Details json
import policyDetails from '../api/policy/details.json';
import coiPdf from '../api/policy/coiPdf.json';
import coverageList from '../api/policy/coverageList.json';
import premiumPaymentSchedule from '../api/policy/premiumPaymentSchedule.json';
import requestPayment from '../api/policy/requestPayment.json';
import premiumPaymentHistoryList from '../api/policy/premiumPaymentHistoryList.json';
import otherPolicyList from '../api/policy/otherPolicyList.json';
import generateDocList from '../api/policy/generateDocList.json';
import generateDocDetail from '../api/policy/generateDocDetail.json';

// New Business json
import newBusinessProposalDetails from '../api/new-business/details.json';
import generateDefermentPdf from '../api/new-business/generateDefermentPdf.json';
import newBusinessOtherProposal from '../api/new-business/otherProposalList.json';
import myContactsDashboard from '../api/my-contacts/dashboard.json';
import agentDashboard from '../api/agent/dashboard.json';
import generateSSOKey from '../api/sso-key/generate-sso-key.json';
import validateSSOKey from '../api/sso-key/validate-sso-key.json';
import nbrequestPayment from '../api/new-business/nbrequestPayment.json';

// Contact json
import contactAllListing from '../api/my-contacts/contact-list-all.json';
import contactSurveyForm from '../api/my-contacts/contact-survey-form.json';
import contactDetails from '../api/my-contacts/contact-details.json';
import contactHistory from '../api/my-contacts/contact-history.json';
import callOutcomeUpdate from '../api/my-contacts/call-outcome-update.json';
import callOutcomeInit from '../api/my-contacts/call-outcome-init.json';
import getContactsDropdown from '../api/my-contacts/get-contacts-dropdown.json';
import validateContact from '../api/my-contacts/validate-contact.json';
import uploadSurveyForm from '../api/my-contacts/upload-survey-form.json';
import updateContact from '../api/my-contacts/update-contact.json';
import deleteContact from '../api/my-contacts/delete-contact.json';

// Global Search
import globalSearchPolicy from '../api/global-search/policy.json';
import globalSearchNewBusiness from '../api/global-search/new-business.json';
import globalSearchMyContacts from '../api/global-search/my-contacts.json';
import globalSearchNotifications from '../api/global-search/notification.json';

// Notification
import allNotification from '../api/Notification/allNotification.json';
import totalUnreadNotification from '../api/Notification/totalUnreadNotification.json';
import updateNotification from '../api/Notification/update-notification.json';

// Content Hub
import articleSections from '../api/content-hub/article-sections.json';
import articleBanners from '../api/content-hub/article-banner.json';
import articleHighlights from '../api/content-hub/article-highlights.json';
import articleList from '../api/content-hub/article-list.json';
import articleTags from '../api/content-hub/article-tags.json';
import article from '../api/content-hub/article.json';
import relatedArticles from '../api/content-hub/related-articles.json';
import featuredArticles from '../api/content-hub/featured-articles.json';
import likeArticle from '../api/content-hub/article-like.json';
import shareArticle from '../api/content-hub/article-share.json';
import visitArticle from '../api/content-hub/article-visit.json';

// Content Links
import folders from '../api/content-links/folders.json';
import periods from '../api/content-links/periods.json';
import languages from '../api/content-links/languages.json';
import types from '../api/content-links/types.json';
import categories from '../api/content-links/categories.json';
import viewLinksHistorySortOptions from '../api/content-links/view-history-links/sort-options.json';
import contentLinks from '../api/content-links/view-history-links/get-content-links.json';
import contentDetails from '../api/content-links/view-history-links/get-content-details.json';
import sharedContentDetails from '../api/content-links/public-content-link-details.json';
import generateNewLeadsSortOptions from '../api/content-links/generate-new-leads/sort-options.json';
import campaigns from '../api/content-links/generate-new-leads/get-campaigns.json';
import campaignArticle from '../api/content-links/generate-new-leads/get-campaign-article.json';
import generateNewLeadsContentDetails from '../api/content-links/generate-new-leads/get-content-details.json';
import updatecontentfolder from '../api/content-links/generate-new-leads/update-contentfolder.json';
import generateNewLink from '../api/content-links/generate-new-leads/generate-new-link.json';
import updatePersonalMessage from '../api/content-links/update-personal-message.json';
import generateQRCode from '../api/content-links/generate-qr-code.json';
import deleteLink from '../api/content-links/delete-link.json';
import publicContentLinkDetails from '../api/content-links/public-content-link-details.json';
import province from '../api/content-links/province.json';
import leadsContact from '../api/content-links/leads-contact.json';
import visitContent from '../api/content-links/content-visit.json';
import interestedClick from '../api/content-links/update-interested-click-count.json';
import updateUrlCopyCount from '../api/content-links/update-copy-url-count.json';
import updateSnsCount from '../api/content-links/update-sns-count.json';
import shareContent from '../api/content-links/content-share.json';
import notInterestedContent from '../api/content-links/content-notInterested.json';
import InterestedContent from '../api/content-links/content-interested.json';
import likeContent from '../api/content-links/content-like.json';
import unlikeContent from '../api/content-links/content-unlike.json';
import knowMoreContent from '../api/content-links/content-knowmore.json';
import submitContact from '../api/content-links/submit-contact.json';
import getProvince from '../api/customer/get-province-dropdown.json';
import uploadMediaByByte from '../api/customer/upload-media-by-byte.json';
import addAppointment from '../api/customer/add-appointment.json';
import viewAgentProfile from '../api/customer/view-agent-profile.json';

// My Profile
import profile from '../api/my-profile/profile.json';
import updatePersonalBrandingLogo from '../api/my-profile/update-personal-branding-logo.json';
import updateProfileLayout from '../api/my-profile/update-profile-layout.json';
import updateProfilePhoto from '../api/my-profile/update-profile-photo.json';
import updateProfileInfo from '../api/my-profile/update-profile-info.json';
import changePassword from '../api/my-profile/change-password.json';

//Calendar
import addAppointmentCalendar from '../api/calendar/add-appointment.json';
import getHistoryList from '../api/calendar/get-history-list.json';

//Performance / persistency
import performanceDashboard from '../api/performance/dashboard.json';
import getPersistency from '../api/performance/persistency.json';
import getPersistencyTeam from '../api/performance/teamPersistency.json';
import getTeamListing from '../api/performance/teamListing.json';

//Ranking
import rankingDashboard from '../api/ranking/dashboard.json';
import rankingTeams from '../api/ranking/get-teams.json';

//Reward
import rewardListing from '../api/reward/get-listing.json';
import activitySummary from '../api/activity/activity-summary.json';

// Performance
import teamSummary from '../api/activity/team-summary.json';

export class MockData {
  static get(url: string): any {
    const apiPath = url.replace(environment.apiEndpoint, '');

    switch (apiPath) {
      // General
      case '/v2/general/getAreaCode':
        return getAreaCode;

      //login module
      case '/v2/auth/login':
        return userLogin;
      case '/v2/auth/refreshtoken':
        return refreshToken;
      case '/v2/auth/sendotp':
        return sendOtp;
      case '/v2/auth/verifyotp':
        return verifyOtp;
      case '/v2/policy/getdashboard':
        return policyDashboard;
      case '/v2/policy/getListing':
        return policyListing;
      case '/v2/newbusiness/getdashboard':
        return newBusinessDashboard;
      case '/v2/newbusiness/getlisting':
        return newBusinessListing;
      case '/v2/auth/genToken':
        return generateToken;
      case '/v2/auth/oktarefreshtoken':
        return refreshOktaToken;

      // Policy Details json
      case '/v2/policy/getdetail':
        return policyDetails;
      case '/v2/policy/gencoi':
        return coiPdf;
      case '/v2/policy/getallcoverage':
        return coverageList;
      case '/v2/policy/genprepayschedule':
        return premiumPaymentSchedule;
      case '/v2/policy/reqpayment':
        return requestPayment;
      case '/v2/policy/getallpremiumpayment':
        return premiumPaymentHistoryList;
      case '/v2/policy/getotherlisting':
        return otherPolicyList;
      case '/v2/policy/doc/getlisting':
        return generateDocList;
      case '/v2/policy/doc/gendetail':
        return generateDocDetail;

      // New Business json
      case '/v2/newbusiness/getdetail':
        return newBusinessProposalDetails;
      case '/v2/newbusiness/reqpayment':
        return nbrequestPayment;
      case '/v2/newbusiness/gendeferment':
        return generateDefermentPdf;
      case '/v2/newbusiness/getotherlisting':
        return newBusinessOtherProposal;
      case '/v2/contact/getdashboard':
        return myContactsDashboard;
      case '/v2/agent/getdashboard':
        return agentDashboard;
      case '/v2/ssomid/genssokey':
        return generateSSOKey;
      case '/v2/ssomid/validatessokey':
        return validateSSOKey;

      // Contact json
      case '/v2/contact/getListing':
        return contactAllListing;
      case '/v2/search/getpolicylisting':
        return globalSearchPolicy;
      case '/v2/search/getnblisting':
        return globalSearchNewBusiness;
      case '/v2/auth/killSession':
        return killSession;
      case '/v2/search/getcontactlisting':
        return globalSearchMyContacts;
      case '/v2/contact/getsurveyform':
        return contactSurveyForm;
      case '/v2/contact/getdetail':
        return contactDetails;
      case '/v2/contact/getcallhistoryListing':
        return contactHistory;
      case '/v2/contact/updateCallStatus':
        return callOutcomeUpdate;
      case '/v2/contact/initCall':
        return callOutcomeInit;
      case '/v2/contact/getSelection':
        return getContactsDropdown;
      case '/v2/contact/validate':
        return validateContact;
      case '/v2/contact/uploadSurveyForm':
        return uploadSurveyForm;
      case '/v2/contact/save':
        return updateContact;
      case '/v2/contact/delete':
        return deleteContact;

      // Notification
      case '/v2/notification/getlisting':
        return allNotification;
      case '/v2/notification/gettotalunread':
        return totalUnreadNotification;
      case '/v2/search/getnotificationlisting':
        return globalSearchNotifications;
      case '/v2/notification/updatenotification':
        return updateNotification;

      // Content Hub
      case '/v2/article/sections':
        return articleSections;
      case '/v2/article/banners':
        return articleBanners;
      case '/v2/article/highlights':
        return articleHighlights;
      case '/v2/article/section/articlelist':
        return articleList;
      case '/v2/article/section/articletags':
        return articleTags;
      case '/v2/article/section/article':
        return article;
      case '/v2/article/related':
        return relatedArticles;
      case '/v2/article/featured':
        return featuredArticles;
      case '/v2/article/like':
        return likeArticle;
      case '/v2/article/unlike':
        return likeArticle;

      // Content Links
      case '/v2/resource/content/getgeneratedlinksfolder':
        return folders;
      case '/v2/contentlinks/periods':
        return periods;
      case '/v2/resource/content/getlanguage':
        return languages;
      case '/v2/contentlinks/types':
        return types;
      case '/v2/resource/content/getcategory':
        return categories;
      case '/v2/contentlinks/viewlinkshistory/sortoptions':
        return viewLinksHistorySortOptions;
      case '/v2/resource/content/getgeneratedlinks':
        return contentLinks;
      case '/v2/resource/content/getcampaigndetail':
        return contentDetails;
      case '/v2/resource/content/getsharedlinkdetails':
        return sharedContentDetails;
      case '/v2/contentlinks/generatenewleads/sortoptions':
        return generateNewLeadsSortOptions;
      case '/v2/resource/content/getcampaigns':
        return campaigns;
      case '/v2/resource/content/getcampaignarticle':
        return campaignArticle;
      case '/v2/contentlinks/generatenewleads/contentdetails':
        return generateNewLeadsContentDetails;
      case '/v2/resource/content/updatefolder':
        return updatecontentfolder;
      case '/v2/resource/content/generatenewlink':
        return generateNewLink;
      case '/v2/resource/content/getfolder':
        return folders;
      case '/v2/resource/content/getlanguage':
        return languages;
      case '/v2/article/share':
        return shareArticle;
      case '/v2/article/visit':
        return visitArticle;
      case '/v2/resource/content/updatepersonalmsg':
        return updatePersonalMessage;
      case '/v2/resource/content/generateqrcode':
        return generateQRCode;
      case '/v2/resource/content/deletelink':
        return deleteLink;
      case '/v2/resource/content/visit':
        return visitContent;
      case '/v2/resource/content/share':
        return shareContent;
      case '/v2/resource/content/like':
        return likeContent;
      case '/v2/resource/content/unlike':
        return unlikeContent;
      case '/v2/resource/content/knowmore':
        return knowMoreContent;
      case '/v2/resource/content/notinterested':
        return notInterestedContent;
      case '/v2/resource/content/interested':
        return InterestedContent;
      case '/v2/contentlinks/publiccontentlinkdetails':
        return publicContentLinkDetails;
      case '/v2/resource/content/interestedclick':
        return interestedClick;
      case '/v2/resource/content/updatesnscount':
        return updateSnsCount;
      case '/v2/customer/getProvince':
        return province;
      case '/v2/customer/GetLeadContacts':
        return leadsContact;
      case '/v2/resource/content/updatecopyurlcount':
        return updateUrlCopyCount;
      case '/v2/customer/viewAgentProfile':
        return viewAgentProfile;
      case '/v2/customer/submitcontact':
        return submitContact;
      case '/v2/customer/GetProvince':
        return getProvince;
      case '/v2/customer/uploadMediaByByte':
        return uploadMediaByByte;
      case '/v2/customer/addappointment':
        return addAppointment;

      // My Profile
      case '/v2/agent/getdetail':
        return profile;
      case '/v2/agent/updateBrandingLogo':
        return updatePersonalBrandingLogo;
      case '/v2/agent/updateProfileLayout':
        return updateProfileLayout;
      case '/v2/agent/uploadProfilePhoto':
        return updateProfilePhoto;
      case '/v2/agent/saveProfile':
        return updateProfileInfo;
      case '/v2/agent/changepassword':
        return changePassword;

      // Calendar
      case '/v2/calendar/add':
        return addAppointmentCalendar;
      case '/v2/calendar/gethistorylisting':
        return getHistoryList;

      //Performance
      case '/v2/performance/getdashboard':
        return performanceDashboard;
      case '/v2/performance/getpersistency':
        return getPersistency;
      case '/v2/performance/persistency/getteams':
        return getPersistencyTeam;
      case '/v2/performance/getteamlisting':
        return getTeamListing;
      case '/v2/performance/getteamsummary':
        return teamSummary;

      //Ranking
      case '/v2/ranking/getdashboard':
        return rankingDashboard;
      case '/v2/ranking/getteams':
        return rankingTeams;

      //Reward
      case '/v2/reward/getlisting':
        return rewardListing;
      case '/v2/agent/getactivitysummarydetail':
        return activitySummary;

      default:
        console.error('apiPath null result: ', apiPath);
        break;
    }
  }
}
