export class ResHeaderInfo {
  result: string;
  resultcode: string;
  resultdescription: string;
  resultuimessage: string;
  timestamp: string;
  language: string;
}

export class BaseResponseBody {}

export class BaseResponseMessage<T extends BaseResponseBody> {
  headerInfo: ResHeaderInfo;
  body: T;
}
