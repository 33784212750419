import { trigger, transition, animate, style } from '@angular/animations';
import { EaseOutExpo } from './css-easings';

let durationInSeconds: number = 0.4;
let easing: string = EaseOutExpo;

export const SlideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate(`${durationInSeconds}s ${easing}`, style({ transform: 'translateX(0%)' })),
  ]),
  transition(':leave', [
    animate(`${durationInSeconds}s ${easing}`, style({ transform: 'translateX(-100%)' })),
  ]),
]);
