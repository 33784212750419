import { SecureStorageService } from '@services/storage/secure-storage.service';
import { Inject, Injectable, Injector, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalOutletService } from '@modules/modal-outlet/modal-outlet.service';
import { SideTabOutletService } from '@modules/side-tab-outlet/site-tab-outlet.service';
import { fromEvent, Subscription } from 'rxjs';
import { UserStore } from '../stores/user.store';
import { PlatformUtilService } from '../utils/platform-util.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class IdleTimerService implements OnDestroy {
  private eventSubs: Subscription;
  private idleTimer: NodeJS.Timeout;
  private modal: TemplateRef<any>;
  private idleMS: number;

  private userStore: UserStore;
  private platformUtilService: PlatformUtilService;
  private modalOutletService: ModalOutletService;
  private sideTabService: SideTabOutletService;
  private secureStorageService: SecureStorageService
  private router: Router;

  constructor(@Inject(DOCUMENT) private document: Document, private injector: Injector) {
    this.userStore = this.injector.get<UserStore>(UserStore);
    this.platformUtilService = this.injector.get<PlatformUtilService>(PlatformUtilService);
    this.modalOutletService = this.injector.get<ModalOutletService>(ModalOutletService);
    this.sideTabService = this.injector.get<SideTabOutletService>(SideTabOutletService);
    this.router = this.injector.get<Router>(Router);
    this.secureStorageService = this.injector.get<SecureStorageService>(SecureStorageService);
    this.eventSubs = fromEvent(this.document, 'click').subscribe(() => this.refresh());
  }

  ngOnDestroy() {
    this.eventSubs.unsubscribe();
  }

  start(modal: TemplateRef<any>, ms: number) {
    this.modal = modal;
    this.idleMS = ms;
    this.refresh();
  }

  refresh() {
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(() => {
      // If user is logged in.
      if (this.userStore.userIsLoggedIn) {
        switch (this.platformUtilService.getDeviceType) {
          case 'web':
            while (this.modalOutletService.currentHistoryIndex > -1) {
              this.modalOutletService.previousHistory();
            }
            while (this.sideTabService.currentHistoryIndex > -1) {
              this.sideTabService.previousHistory();
            }
            this.modalOutletService.showModal(this.modal);
            break;
          case 'tablet':
          case 'mobile':
            this.userStore.clearUser();
            this.secureStorageService.get('isBiometricSetup').then((isBiometricSetup) => {
              if (isBiometricSetup === true) {
                this.router.navigate(['/login-options']);
              } else {
                this.secureStorageService.get('isPinSetup').then((isPinSetup) => {
                  if (isPinSetup === true) {
                    this.router.navigate(['/login-with-pin']);
                  }
                });
              }
            });
            break;
          default:
            console.error('Unknown Device Type. (Idle Timer Services)');
            break;
        }
      }
      this.refresh();
    }, this.idleMS);
  }
}