import { Injectable } from '@angular/core';
import { Store } from '../classes/store.class';

export class ViewAsAgentState {
  agentCode: string | null;
  agentName: string | null;
  agentRank: string | null;
  isViewing: boolean | null;
}

@Injectable({
  providedIn: 'root',
})
export class ViewAsAgentStore extends Store<ViewAsAgentState> {
  constructor() {
    super(new ViewAsAgentState());
  }

  get getIsViewing(): boolean {
    return this.state.isViewing;
  }

  get getAgentRank(): string {
    return this.state.agentRank;
  }

  setViewAsAgent(viewAsAgent: ViewAsAgentState) {
    this.setState({
      ...this.state,
      ...viewAsAgent,
    });
  }

  getViewAsAgent() {
    return this.state;
  }

  clearViewAsAgent(): void {
    this.setState({
      agentCode: null,
      agentName: null,
      agentRank: null,
      isViewing: null
    });
  }
}
