import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  AccessToken,
  AuthState,
  hasErrorInUrl,
  IdxMessage,
  IdxStatus,
  IdxTransaction,
  NextStep,
  OktaAuth,
  Tokens,
  updatePassword,
  UpdatePasswordPayload,
} from '@okta/okta-auth-js';
import { BehaviorSubject, defer, Observable, of, Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { OKTA_AUTH } from 'src/environments/okta-config';

export interface AppAuthState {
  transaction: IdxTransaction | undefined;
  authState: AuthState;
}

const defaultAppAuthState: AppAuthState = {
  authState: {
    isAuthenticated: false,
  },
  transaction: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class OktaAuthService implements OnDestroy {
  public destroySub$: Subject<void> = new Subject<void>();
  public appAuthStateSub$: BehaviorSubject<AppAuthState> = new BehaviorSubject<AppAuthState>(
    defaultAppAuthState
  );

  public authState$: Observable<AuthState> = this.appAuthStateSub$
    .asObservable()
    .pipe(map((appAuthState) => appAuthState.authState));
  public idxTransactionMessages$: Observable<IdxMessage[]> = this.appAuthStateSub$
    .asObservable()
    .pipe(map((appAuthState) => appAuthState.transaction?.messages as IdxMessage[]));

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.authStateHandler = this.authStateHandler.bind(this);
    this.oktaAuth.authStateManager.subscribe(this.authStateHandler);
    this.oktaAuth.start();

    this.appAuthStateSub$
      .asObservable()
      .pipe(
        filter((state) => !!state.transaction),
        distinctUntilKeyChanged('transaction'),
        map((state) => state.transaction as IdxTransaction),
        takeUntil(this.destroySub$)
      )
      .subscribe((transaction) => {});

    this.appAuthStateSub$
      .asObservable()
      .pipe(
        filter((state) => !!state.transaction && !!state.transaction.tokens),
        map((state) => state.transaction?.tokens as Tokens),
        takeUntil(this.destroySub$)
      )

      .subscribe((tokens) => {
        this.oktaAuth.tokenManager.setTokens(tokens);
      });
  }

  public startIdxFlow(): Observable<NextStep | undefined> {
    const maxAge = '900'; // means 15 minutes as it is required by myAccount API
    const acrValues = 'urn:okta:loa:2fa:any:ifpossible';
    return defer(() => this.oktaAuth.idx.authenticate({ maxAge, acrValues })).pipe(
      map((transaction) => this.transactionStateHandler(transaction))
    );
  }

  public startLoginFlow(): Observable<NextStep | undefined> {
    return defer(() => this.oktaAuth.idx.startTransaction({ flow: 'login' })).pipe(
      map((transaction) => this.transactionStateHandler(transaction))
    );
  }

  public startResend(): Observable<IdxTransaction | undefined> {
    if (this.oktaAuth.idx.canProceed()) {
      return defer(() => this.oktaAuth.idx.proceed({ resend: true })).pipe(
        map((transaction) => this.transactionStateHandler(transaction))
      );
    }
  }

  public startRecoverIdxFlow(): Observable<NextStep | undefined> {
    return defer(() => this.oktaAuth.idx.startTransaction({ flow: 'recoverPassword' })).pipe(
      map((transaction) => this.transactionStateHandler(transaction))
    );
  }

  public async revokeAccessToken() {
    const access = (await this.oktaAuth.tokenManager.get('accessToken')) as AccessToken;
    await this.oktaAuth.revokeAccessToken(access);

    await this.oktaAuth.tokenManager.clear();
  }

  public setToken(tokens: any) {
    this.oktaAuth.tokenManager.setTokens(tokens);
  }

  public async cancelTransaction() {
    //clear transaction first before cancel
    const session = this.oktaAuth.session.get();
    if ((await session).status === 'ACTIVE') {
      this.oktaAuth.closeSession();
    }

    // may not be necessary but uses the OktaAuth event emitter to emit
    // token removal events in addition to the same thing that
    // auth.storageManager.getTokenStorage.clearStorage() does
    this.oktaAuth.tokenManager.clear();

    // equivalent to auth.storageManager.getTransactionStorage.clearStorage()
    // clearSharedStorage: true only clears the current saved
    // transaction from sharedStorage
    // notably this doesn't seem to allow passing of clearIdxResponse: true
    // hence we make a separate call for that below
    this.oktaAuth.transactionManager.clear({ clearSharedStorage: true });

    // this allows you to clear the entire sharedTransactionStorage
    // instead of just the current saved transaction
    const storageManager = this.oktaAuth.storageManager;
    storageManager?.getSharedTansactionStorage()?.clearStorage();

    storageManager?.getHttpCache()?.clearStorage();

    // originalUri only seems to be set in some flows, e.g. signInWithRedirect(),
    // could use some more clarity on whether we need to clear this ever
    storageManager?.getOriginalUriStorage()?.clearStorage();

    // you can clear the sneaky IdxResponseStorage with clearIdxResponse: true
    storageManager?.getTransactionStorage()?.clearStorage();
  }

  public cancelIdxFlow(): Observable<NextStep | undefined> {
    return defer(() => this.oktaAuth.idx.cancel()).pipe(
      map((transaction) => this.transactionStateHandler(transaction))
    );
  }

  public proceedIdxFlow(inputValues: any): Observable<NextStep | undefined> {
    return defer(() => this.oktaAuth.idx.proceed(inputValues)).pipe(
      map((transaction) => this.transactionStateHandler(transaction))
    );
  }

  public async getUserInfo() {
    const userInfo = await this.oktaAuth.token.getUserInfo(
      this.appAuthStateSub$.value.authState.accessToken,
      this.appAuthStateSub$.value.authState.idToken
    );
  }

  public handleRedirects(): Observable<NextStep | undefined> {
    const { href, search } = this.doc.location;
    if (!href.includes('/login/callback')) {
      return of(undefined);
    }

    this.router.navigateByUrl('/');

    if (hasErrorInUrl(search)) {
      const query = this.router.parseUrl(search).queryParamMap;
      throw new Error(`${query.get('error')}: ${query.get('error_description')}`);
    }

    if (this.oktaAuth.idx.isEmailVerifyCallback(href)) {
      return defer(() => this.oktaAuth.idx.handleEmailVerifyCallback(search)).pipe(
        map((transaction) => this.transactionStateHandler(transaction as IdxTransaction))
      );
    }

    return of(undefined);
  }

  public async logout(): Promise<void> {
    await this.oktaAuth.signOut();
  }

  public ngOnDestroy(): void {
    this.oktaAuth.authStateManager.unsubscribe(this.authStateHandler);
    this.destroySub$.next();
    this.destroySub$.complete();
    this.appAuthStateSub$.next(defaultAppAuthState);
    this.appAuthStateSub$.complete();
  }

  private transactionStateHandler(transaction: IdxTransaction): any {
    const appState = this.appAuthStateSub$.getValue();
    this.appAuthStateSub$.next({ ...appState, transaction });

    const status = transaction.status;
    if (status === IdxStatus.SUCCESS || status === IdxStatus.CANCELED) {
      return undefined;
    }

    if (transaction.status === IdxStatus.FAILURE) {
      throw 'Idx error';
    }
    return transaction;
  }

  private authStateHandler(authState: AuthState): void {
    const appState = this.appAuthStateSub$.getValue();
    this.appAuthStateSub$.next({ ...appState, authState });
  }

  loggedInAccessToken: string = '';
  setLoggedInAccessToken(oToken) {
    this.loggedInAccessToken = oToken;
  }
}