// Angular
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { SubscriptionLike } from 'rxjs';
// Project
import { FadeAndScaleIn } from '@animations/fade-and-scale-in.animation';
// Component
import { ModalOutletService } from './modal-outlet.service';

@Component({
  selector: 'modal-outlet',
  templateUrl: './modal-outlet.component.html',
  styleUrls: ['./modal-outlet.component.scss'],
  animations: [FadeAndScaleIn],
})
export class ModalOutletComponent implements OnInit {
  locationSubs: SubscriptionLike;

  constructor(
    private location: Location,
    private modalOutletService: ModalOutletService,
  ) {
    this.locationSubs = this.location.subscribe((event: any) => {
      if (event.state && !event.state.modalOutletHistoryIndex) {
        this.modalOutletService.close();
      }
    });
  }

  ngOnInit(): void {}

  get modalTemplate(): TemplateRef<any> | undefined {
    return this.modalOutletService.modalTemplate;
  }

  get full(): boolean {
    return this.modalOutletService.full;
  }
}
