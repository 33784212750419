import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { SecureStorageService } from '@services/storage/secure-storage.service';
import { url } from 'inspector';
import { Observable } from 'rxjs';
import { StorageKey } from 'src/app/helpers/enum';
import { UserStore } from 'src/app/stores/user.store';
import { PlatformUtilService } from '../platform-util.service';
import { OktaAuthService } from '@services/okta/okta-auth-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private secureStorageService: SecureStorageService;
  private router: Router;
  private navController: NavController;
  private userStore: UserStore;
  private platformUtil: PlatformUtilService;

  constructor(private injector: Injector, private oktaAuthService: OktaAuthService) {
    this.secureStorageService = this.injector.get<SecureStorageService>(SecureStorageService);
    this.navController = this.injector.get<NavController>(NavController);
    this.userStore = this.injector.get<UserStore>(UserStore);
    this.router = this.injector.get<Router>(Router);
    this.platformUtil = this.injector.get<PlatformUtilService>(PlatformUtilService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currURL: string = state.url.split('?')[0];
    const loginResult = route.queryParamMap.get('data');
    const pageLoggedIn = route.queryParamMap.get('page');

    if (environment.mockAutoLogin) {
      switch (currURL) {
        //login module
        case '/login':
        case '/update-national-id':
        case '/login-verify-otp':
        case '/login-setup-biometrics':
        case '/login-setup-pin':
        case '/login-options':
        case '/login-with-pin':
          this.router.navigate(['dashboard']);
          console.log('Auto Login');
          return false;

        default:
          return true;
      }
    }

    return this.secureStorageService.init().then(() =>
      this.secureStorageService
        .get(StorageKey.AccessToken)
        .then((token) => {
          let isLoggedIn = null;
          if (this.platformUtil.getDeviceType === 'web') {
            isLoggedIn = this.userStore.state.accessToken;
          } else {
            isLoggedIn = token;
          }
          const paramKey = route.queryParams.ssoKey;

          switch (currURL) {
            //login module
            case '/login':
              if (isLoggedIn) {
                // To prevent infinite loop where on login via password pressed will be stuck at login options page or login with pin
                this.secureStorageService.get('isLoginViaPassword').then((isLoginViaPassword) => {
                  if (isLoginViaPassword === true) {
                    return true;
                  } else {
                    this.secureStorageService.get('isBiometricSetup').then((isBiometricSetup) => {
                      if (isBiometricSetup === true) {
                        this.router.navigate(['/login-options']);
                        return false;
                      } else {
                        this.secureStorageService.get('isPinSetup').then((isPinSetup) => {
                          if (isPinSetup === true) {
                            this.router.navigate(['/login-with-pin']);
                            return false;
                          } else {
                            // Fallback
                            this.router.navigate(['dashboard']);
                            return false;
                          }
                        });
                      }
                    });
                  }
                });
              } else {
                return true;
              }
              break;
            case '/update-national-id':
            case '/login-verify-otp':
            case '/login-setup-biometrics':
            case '/login-setup-pin':
            case '/login-options':
            case '/login-with-pin':
              // All these only accessible when user is not logged in
              // "isLoggedIn = true" defined as having token in SecureStorage / user store
              if (isLoggedIn) {
                // If user is logged in, throw them back to dashboard screen
                this.router.navigate(['dashboard']);
                return false;
              } else {
                // otherwise, allow them to route to those screens
                return true;
              }
              break;

            case '/reset-password':
              if (loginResult === 'reset' && pageLoggedIn === 'settings') {
                if (isLoggedIn) {
                  return true;
                } else {
                  this.oktaAuthService.logout();
                  this.router.navigate(['login']);
                  return false;
                }
              } else {
                // allow for reset-password for forgot
                return true;
              }
              break;
            default:
              if (isLoggedIn) {
                return true;
              } else {
                this.oktaAuthService.logout();
                if (paramKey !== '' && paramKey !== undefined) {
                  this.router.navigate(['login'], {
                    queryParams: { ssoKey: paramKey, reroute: currURL.split('?')[0] },
                  });
                  return false;
                } else {
                  this.router.navigate(['login']);
                  return false;
                }
              }
          }
        })
        .catch((_e) => {
          // this.authService.clearStorage();
          console.error(_e);
          this.navController.navigateRoot(['login']);
          return false;
        })
    );
  }
}