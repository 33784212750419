import { HTTP } from '@ionic-native/http/ngx';
import { Injectable } from '@angular/core';

//https://github.com/silkimen/cordova-plugin-advanced-http
//https://www.otricks.com/how-to-implement-ssl-pinning-in-ionic-5-or-html5-app/
//https://github.com/silkimen/cordova-plugin-advanced-http/issues/74

// Note: Place SSL cert in src/certificate folder
// Requires .cer or .der to works, .pem format not working
// https://github.com/AFNetworking/AFNetworking/issues/3768
// After placing cert in folder, add resource files in config.

// Note: For some reason iOS project.pbxproj doesn't remove old cert file, might have to manually remove it.
// Might need to remove files in MyAllianz/Resources/src/certificate to refresh project.pbxproj

// Note: Android no need to declare resource file in config, seems to be working without declaring it.
// Make sure the certs are in this folder app/src/main/assets/www/certificates.

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';
type ResponseType = 'json' | 'blob' | 'arraybuffer' | 'text';

@Injectable({
  providedIn: 'root',
})
export class NativeHttpHandler {
  constructor(private http: HTTP) {}

  sendRequest(
    method: HttpMethod,
    path: string,
    body: any = {},
    params: any = {},
    header: any = {},
    responseType: ResponseType = 'json'
  ) {
    const options: any = {};
    options.method = method;
    options.headers = header;
    options.responseType = responseType;

    if (method === 'post' || method === 'put') {
      options.data = body;
      options.params = params;
      if (body instanceof FormData) {
        options.serializer = 'multipart';
      } else {
        options.serializer = 'json';
      }
    } else {
      options.params = params;
    }

    this.http.setHeader('*', 'Cache-Control', 'no-store');
    this.http.setHeader('*', 'Content-Type', 'application/json');
    this.http.setHeader('*', 'Accept', 'application/json');
    this.http.setDataSerializer('json');
    return this.http.sendRequest(path, options);
  }
}
