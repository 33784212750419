import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat2'
})
export class NumberFormat2Pipe implements PipeTransform {
  transform(value: any, decimal: any = 1): string {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(decimal) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(decimal) + 'K';
    } else {
      return value?.toString();
    }
  }
}
