import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { SlideDown } from '@animations/slide-down.animation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [SlideDown],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input('control') control: AbstractControl;
  @Input('options') options: any[];
  @Input('customAction') customAction: any;
  @Input('icon') icon: string;
  @Input('floatingLabel') floatingLabel: string = 'Label';
  @Input() placeholder: string = '';
  @Input('hideLabel') hideLabel: boolean = false;
  @Input('hasPlaceholder') hasPlaceholder: boolean = false;
  @Input('placeholderText') placeholderText: string = '';
  @Input('isAltDesign') isAltDesign: boolean = false;
  @Output('dropdownValueSelected') dropdownValueSelected: any = new EventEmitter();
  
  selectedLabel: string = '';
  customDropdownIsShown: boolean = false;

  controlSubs: Subscription;

  constructor(private elementRef: ElementRef, private changeDetect: ChangeDetectorRef) {}

  get isDisabled(): boolean {
    return this.control.disabled;
  }

  ngOnInit() {
    this.controlSubs = this.control.valueChanges.subscribe(() => {
      this.updateCustomDropdownLabel();
      this.changeDetect.detectChanges();
    });

    this.updateCustomDropdownLabel();
  }

  ngOnDestroy(): void {
    if (this.controlSubs) {
      this.controlSubs.unsubscribe();
    }
  }

  updateCustomDropdownLabel(): void {
    if (this.options.length > 0) {
      this.selectedLabel =
        this.options.find((option) => option.value === this.control.value)?.label ??
        (typeof this.control.value === 'string' ? this.control.value : this.options[0]?.label) ??
        null;
      if (this.selectedLabel === this.placeholder) {
        this.selectedLabel = null;
      }
    } else {
      this.selectedLabel = null;
    }
  }

  updateCustomDropdown(option: any): void {
    this.control.setValue(option.value);
    this.customDropdownIsShown = false;
    this.dropdownValueSelected.emit()
  }

  runCustomActionCallback(): void {
    this.customAction.callback();
    this.customDropdownIsShown = false;
  }

  filteredOptions() {
    return this.options.filter((e) => e.status === true);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.customDropdownIsShown = false;
    }
  }
}
