import { NgModule } from '@angular/core';
import { PreloadAllModules, NoPreloading, RouterModule, Routes } from '@angular/router';
import { PlatformGuard } from './utils/guard/platform.guard';
import { AuthGuard } from './utils/guard/auth.guard';
import { LoginFlowGuard } from './utils/guard/login-flow.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'update-national-id',
    canActivate: [AuthGuard, LoginFlowGuard],
    loadChildren: () =>
      import('./pages/login-update-national-id/login-update-national-id.module').then(
        (m) => m.LoginUpdateNationalIdPageModule
      ),
  },
  {
    path: 'login-verify-mobile-email',
    canActivate: [LoginFlowGuard],
    loadChildren: () =>
      import('./pages/login-verify-mobile-email/login-verify-mobile-email.module').then(
        (m) => m.LoginVerifyMobileEmailPageModule
      ),
  },
  {
    path: 'login-set-password',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/login-set-password/login-set-password.module').then((m) => m.LoginSetPasswordPageModule),
  },
  {
    path: 'login-verify-otp',
    canActivate: [AuthGuard, LoginFlowGuard],
    loadChildren: () =>
      import('./pages/login-verify-otp/login-verify-otp.module').then((m) => m.LoginVerifyOtpPageModule),
  },
  {
    path: 'login-setup-biometrics',
    canActivate: [PlatformGuard],
    loadChildren: () =>
      import('./pages/login-setup-biometric/login-setup-biometrics.module').then(
        (m) => m.LoginSetupBiometricsPageModule
      ),
  },
  {
    path: 'login-options',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/login-options/login-options.module').then((m) => m.LoginOptionsPageModule),
  },
  {
    path: 'login-with-pin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/login-with-pin/login-with-pin.module').then((m) => m.LoginWithPinPageModule),
  },
  {
    path: 'login-setup-pin',
    canActivate: [PlatformGuard],
    loadChildren: () => import('./pages/login-setup-pin/login-setup-pin.module').then((m) => m.LoginSetupPinPageModule),
  },
  {
    path: 'reset-password',
    // canActivate: [PlatformGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'global-search',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/global-search/global-search.module').then((m) => m.GlobalSearchPageModule),
  },
  {
    path: 'kitchen-sink',
    loadChildren: () => import('./pages/kitchen-sink/kitchen-sink.module').then((m) => m.KitchenSinkPageModule),
  },

  //Policy
  {
    path: 'policy-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/policy-dashboard/policy-dashboard.module').then((m) => m.PolicyDashboardPageModule),
  },
  {
    path: 'policy-listing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/policy-listing/policy-listing.module').then((m) => m.PolicyListingPageModule),
  },
  {
    path: 'policy-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/policy-details/policy-details.module').then((m) => m.PolicyDetailsPageModule),
  },

  //New Business
  {
    path: 'new-business-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/new-business-dashboard/new-business-dashboard.module').then(
        (m) => m.NewBusinessDashboardPageModule
      ),
  },
  {
    path: 'new-business-listing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/new-business-listing/new-business-listing.module').then((m) => m.NewBusinessListingPageModule),
  },
  {
    path: 'new-business-details',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/new-business-details/new-business-details.module').then((m) => m.NewBusinessDetailsPageModule),
  },

  //My Profile
  {
    path: 'my-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/my-profile/my-profile.module').then((m) => m.MyProfilePageModule),
  },
  {
    path: 'my-profile-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/my-profile-edit/my-profile-edit.module').then((m) => m.MyProfileEditPageModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
  },

  //My contacts
  {
    path: 'my-contacts-creation',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/my-contacts-creation/my-contacts-creation.module').then((m) => m.MyContactsCreationPageModule),
  },
  {
    path: 'my-contacts-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/my-contacts-dashboard/my-contacts-dashboard.module').then((m) => m.MyContactsDashboardPageModule),
  },
  {
    path: 'my-contacts-listing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/my-contacts-listing/my-contacts-listing.module').then((m) => m.MyContactsListingPageModule),
  },
  {
    path: 'my-contacts-details',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/my-contacts-details/my-contacts-details.module').then((m) => m.MyContactsDetailsPageModule),
  },

  //Notificatications
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsPageModule),
  },
  {
    path: 'notifications-details',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/notifications-details/notifications-details.module').then(
        (m) => m.NotificationsDetailsPageModule
      ),
  },

  {
    path: 'error-page-not-found',
    loadChildren: () => import('./pages/error-not-found/error-not-found.module').then((m) => m.ErrorNotFoundPageModule),
  },
  {
    path: 'error-internal-server',
    loadChildren: () =>
      import('./pages/error-internal-server/error-internal-server.module').then((m) => m.ErrorInternalServerPageModule),
  },

  //Articles
  {
    path: 'contenthub/home',
    loadChildren: () => import('./pages/articles/articles.module').then((m) => m.ArticlesPageModule),
  },
  {
    path: 'contenthub/articles/:categoryId',
    loadChildren: () =>
      import('./pages/articles-category/articles-category.module').then((m) => m.ArticlesCategoryPageModule),
  },
  {
    path: 'contenthub/articles/:categoryId/:articleId',
    loadChildren: () =>
      import('./pages/articles-details/articles-details.module').then((m) => m.ArticlesDetailsPageModule),
  },

  //View links history
  {
    path: 'view-links-history',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/view-links-history/view-links-history.module').then((m) => m.ViewLinksHistoryPageModule),
  },
  {
    path: 'view-links-details/:contentLinkId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/view-links-details/view-links-details.module').then((m) => m.ViewLinksDetailsPageModule),
  },
  {
    path: 'leads-contact',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/leads-contact/leads-contact.module').then((m) => m.LeadsContactPageModule),
  },

  //Generate New Leads
  {
    path: 'generate-new-lead',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/generate-new-link/generate-new-link.module').then((m) => m.GenerateNewLinkPageModule),
  },
  {
    path: 'content-link/:contentNo/view',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/view-content-link/view-content-link.module').then((m) => m.ViewContentLinkPageModule),
  },
  {
    path: 'content-link/:contentNo/customise',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/customise-content-link/customise-content-link.module').then(
        (m) => m.CustomiseContentLinkPageModule
      ),
  },

  //Public Content Link Details
  {
    path: 'contenthub/:contentLinkId',
    loadChildren: () =>
      import('./pages/public-content-link-details/public-content-link-details.module').then(
        (m) => m.PublicContentLinkDetailsPageModule
      ),
  },

  //Performance
  {
    path: 'performance',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/performance/performance.module').then((m) => m.PerformancePageModule),
  },
  {
    path: 'performance-persistency',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/performance-persistency/performance-persistency.module').then(
        (m) => m.PerformancePersistencyPageModule
      ),
  },
  {
    path: 'performance-persistency-listing',
    loadChildren: () =>
      import('./pages/performance-persistency-listing/performance-persistency-listing.module').then(
        (m) => m.PerformancePersistencyListingPageModule
      ),
  },

  //Ranking
  {
    path: 'ranking',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/ranking/ranking.module').then((m) => m.RankingPageModule),
  },
  {
    path: 'ranking-unit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/ranking-unit/ranking-unit.module').then((m) => m.RankingUnitPageModule),
  },

  //Reward History
  {
    path: 'reward-history',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/reward-history/reward-history.module').then((m) => m.RewardHistoryPageModule),
  },

  //Activity
  {
    path: 'activity-summary',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/activity-summary/activity-summary.module').then((m) => m.ActivitySummaryPageModule),
  },
  {
    path: 'activity-contact-leads',
    loadChildren: () =>
      import('./pages/activity-contact-leads/activity-contact-leads.module').then(
        (m) => m.ActivityContactLeadsPageModule
      ),
  },
  {
    path: 'activity-calls-made',
    loadChildren: () =>
      import('./pages/activity-calls-made/activity-calls-made.module').then((m) => m.ActivityCallsMadePageModule),
  },

  {
    path: 'activity-issuance',
    loadChildren: () =>
      import('./pages/activity-issuance/activity-issuance.module').then((m) => m.ActivityIssuancePageModule),
  },
  {
    path: 'activity-appointment-set',
    loadChildren: () =>
      import('./pages/activity-appointment-set/activity-appointment-set.module').then(
        (m) => m.ActivityAppointmentSetPageModule
      ),
  },

  //Other documents
  {
    path: 'other-documents',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/other-documents/other-documents.module').then((m) => m.OtherDocumentsPageModule),
  },
  {
    path: 'activity-summary-agent',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/activity-summary-agent/activity-summary-agent.module').then(
        (m) => m.ActivitySummaryAgentPageModule
      ),
  },
  // Wildcard Route to handle invalid url
  {
    path: '**',
    redirectTo: 'error-page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
