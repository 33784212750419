import { AuthStore } from 'src/app/stores/auth.store';
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  KillSessionRequestBody,
  KillSessionResponseMessage,
  LoginRequestBody,
  LoginResponseMessage,
  SendOtpRequestBody,
  SendOtpResponseMessage,
  RefreshTokenResponseMessage,
  VerifyOtpRequestBody,
  VerifyOtpResponseMessage,
  GenerateTokenRequestBody,
  GenerateTokenResponseMessage,
  RefreshOktaTokenResponseMessage,
} from '@models/identity/authentication';
import {
  GenerateSsoKeyRequestBody,
  GenerateSsoKeyResponseMessage,
  ValidateSsoKeyRequestBody,
  ValidateSsoKeyResponseMessage,
} from '@models/identity/sso-key';
import { SecureStorageService } from '@services/storage/secure-storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageKey } from 'src/app/helpers/enum';
import { UserStore } from 'src/app/stores/user.store';
import { GenerateMsgUtilService } from 'src/app/utils/generate-msg-util.service';
import { environment } from '../../../environments/environment';
import { OktaAuthService } from '@services/okta/okta-auth-service';

const apiURL: string = environment.apiEndpoint;
const apiVersion: string = environment.apiVersion;

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  mockError: boolean = environment.mockError;

  private httpClient: HttpClient;
  private userStore: UserStore;
  private secureStorageService: SecureStorageService;
  private generateMsgUtil: GenerateMsgUtilService;

  constructor(private injector: Injector, private oktaAuthService: OktaAuthService, private authStore: AuthStore) {
    this.httpClient = this.injector.get<HttpClient>(HttpClient);
    this.userStore = this.injector.get<UserStore>(UserStore);
    this.secureStorageService = this.injector.get<SecureStorageService>(SecureStorageService);
    this.generateMsgUtil = this.injector.get<GenerateMsgUtilService>(GenerateMsgUtilService);
  }

  login(requestBody: LoginRequestBody): Observable<LoginResponseMessage> {
    // Generate Request message
    const requestMsg = this.generateMsgUtil.generateRequestMessage(requestBody);

    return this.httpClient.post<LoginResponseMessage>(`${apiURL}/${apiVersion}/auth/login`, requestMsg).pipe(map((response) => response));
  }

  verifyNationalID(requestBody: LoginRequestBody): Observable<LoginResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(requestBody);

    return this.httpClient.post<LoginResponseMessage>(`${apiURL}/${apiVersion}/auth/login`, requestMsg).pipe(map((response) => response));
  }

  sendOtp(requestBody: SendOtpRequestBody): Observable<SendOtpResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(requestBody);

    return this.httpClient.post<SendOtpResponseMessage>(`${apiURL}/${apiVersion}/auth/sendotp`, requestMsg).pipe(map((response) => response));
  }

  verifyOtp(requestBody: VerifyOtpRequestBody): Observable<VerifyOtpResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(requestBody);
    return this.httpClient.post<VerifyOtpResponseMessage>(`${apiURL}/${apiVersion}/auth/verifyotp`, requestMsg).pipe(map((response) => response));
  }

  refreshToken(): Observable<RefreshTokenResponseMessage> {

    const requestMsg = this.generateMsgUtil.generateRequestMessage({
      agentCode: this.userStore.state.agentCode,
      deviceId: null,
    });

    return this.httpClient.post<RefreshTokenResponseMessage>(`${apiURL}/${apiVersion}/auth/refreshtoken`, requestMsg).pipe(
      map((response) => {
        // SAVE new token from refreshToken Api
        this.secureStorageService.set(StorageKey.AccessToken, response.body.accessToken);
        this.secureStorageService.set(StorageKey.RefreshToken, response.body.refreshAccessToken);
        this.secureStorageService.set(StorageKey.TokenExpiry, response.body.expires);

        // TODO: To verify if able to save Okta token to SDK
        // this.oktaAuthService.setToken(response.body.oTokens);

        this.userStore.setState({
          ...this.userStore.state,
          accessToken: response.body.accessToken,
          refreshAccessToken: response.body.refreshAccessToken,
          tokenExpiresIn: response.body.expires,
        });
        return response;
      })
    );
  }

  killSession(requestBody: KillSessionRequestBody): Observable<KillSessionResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(requestBody);
    return this.httpClient.post<KillSessionResponseMessage>(`${apiURL}/${apiVersion}/auth/killSession`, requestMsg).pipe(
      map((response) => {
        if (requestBody.type === 3) {
          this.clearStorage();
        }
        return response;
      })
    );
  }

  generateSsoKey(reqBody: GenerateSsoKeyRequestBody): Observable<GenerateSsoKeyResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(reqBody);
    return this.httpClient
      .post<GenerateSsoKeyResponseMessage>(`${apiURL}/${apiVersion}/ssomid/genssokey`, requestMsg)
      .pipe(map((response) => response));
  }

  validateSsoKey(requestBody: ValidateSsoKeyRequestBody): Observable<ValidateSsoKeyResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(requestBody);
    return this.httpClient
      .post<ValidateSsoKeyResponseMessage>(`${apiURL}/${apiVersion}/ssomid/validatessokey`, requestMsg)
      .pipe(map((response) => response));
  }

  generateToken(requestBody: GenerateTokenRequestBody): Observable<GenerateTokenResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage(requestBody);

    return this.httpClient.post<GenerateTokenResponseMessage>(`${apiURL}/${apiVersion}/auth/genToken`, requestMsg).pipe(map((response) => response));
  }

  refreshOktaToken(): Observable<RefreshOktaTokenResponseMessage> {
    const requestMsg = this.generateMsgUtil.generateRequestMessage({
      agentCode: this.getAgentCode(),
      deviceId: null,
    });

    return this.httpClient
      .post<RefreshOktaTokenResponseMessage>(`${apiURL}/${apiVersion}/auth/oktarefreshtoken`, requestMsg)
      .pipe(map((response) => response));
  }

  async getAgentCode() {
    return await this.secureStorageService.get(StorageKey.AgentCode);
  }

  clearStorage() {
    this.secureStorageService.remove(StorageKey.AccessToken);
    this.secureStorageService.remove(StorageKey.AgentCode);
    this.secureStorageService.remove(StorageKey.RefreshToken);
    this.secureStorageService.remove(StorageKey.TokenExpiry);
    this.secureStorageService.remove(StorageKey.OAccessToken);
    this.secureStorageService.remove(StorageKey.ORefreshToken);

    this.userStore.clearUser();
  }
}