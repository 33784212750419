import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NewGuid } from '..//helpers/guid';
import { SecureStorageService } from './storage/secure-storage.service';

const SESSION = 'session';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _session: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private secureStorageService: SecureStorageService
  ) {}

  async getSession(): Promise<string> {
    if (isPlatformBrowser(this.platformId)) {
      this._session = await this.secureStorageService.get(SESSION);

      if (!this._session) {
        this._session = NewGuid();
        this.secureStorageService.set(SESSION, this._session);
      }

      return this._session;
    }
  }
}
