import { OktaAuth } from '@okta/okta-auth-js';
import { FactoryProvider, InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

const oidcConfig = {
  issuer: 'https://aia-kh-uat.okta.com/oauth2/aus3lxxawhNChK3k33l7',
  clientId: '0oa3lxp9f7rRzsl043l7',
  redirectUri: 'https://alpauat.aia.com.kh/logincallback',
  scopes: ['offline_access', 'openid', 'profile', 'email', 'okta.myAccount.password.manage'],
  pkce: true,
  tokenManager: { 
    autoRenew: false,
    syncStorage: true,
  },
};

export const OKTA_AUTH = new InjectionToken('okta-auth');

const oktaAuthFactory = (router: Router, doc: any) => {
  const params = router.parseUrl(doc.location.search);
  const state = params.queryParamMap.get('state') ?? undefined;
  const recoveryToken = params.queryParamMap.get('recoveryToken') ?? undefined;
  const redirectUri = `${doc.location.origin}/logincallback`;

  return new OktaAuth({ ...oidcConfig, redirectUri, state, recoveryToken });
};

export const OKTA_PROVIDER: FactoryProvider = {
  provide: OKTA_AUTH,
  useFactory: oktaAuthFactory,
  deps: [Router, DOCUMENT],
};
