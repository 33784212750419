import { Injectable } from '@angular/core';
import { Store } from '../classes/store.class';

export class UserState {
  agentCode: string | null;
  agentName: string | null;
  accessToken: string | null;
  refreshAccessToken: string | null;
  tokenExpiresIn: string | null;
  agentRankGroup: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class UserStore extends Store<UserState> {
  constructor() {
    super(new UserState());
  }

  get userIsLoggedIn(): boolean {
    return !!this.state.agentCode;
  }

  get userIsLeader(): boolean {
    return this.state?.agentRankGroup ? this.state?.agentRankGroup !== "AG" : false;
  }

  setUser(user: UserState) {
    this.setState({
      ...this.state,
      ...user,
    });
  }

  getUser() {
    return this.state;
  }

  clearUser(): void {
    this.setState({
      agentCode: null,
      agentName: null,
      accessToken: null,
      refreshAccessToken: null,
      tokenExpiresIn: null,
      agentRankGroup: null,
    });
  }
}
