// HeaderInfo within the REST message
export class ReqHeaderInfo {
  timestamp: string;
  language: string;
}

// Request Body 
export class BaseRequestBody {}

// Request Message
export class BaseRequestMessage<T extends BaseRequestBody> {
    headerInfo: ReqHeaderInfo;
    body: T
}