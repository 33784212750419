import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingUtilService {
  loadingCount: Observable<number>;

  private _loadingCount: BehaviorSubject<number>;

  constructor() {
    this._loadingCount = new BehaviorSubject(0);
    this.loadingCount = this._loadingCount.asObservable();
  }

  startLoading() {
    this._loadingCount.next(this._loadingCount.getValue() + 1);
  }

  stopLoading() {
    if (this._loadingCount.getValue() > 0) {
      this._loadingCount.next(this._loadingCount.getValue() - 1);
    }
  }

  isLoading() {
    return this._loadingCount.getValue;
  }
}
