import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformUtilService } from '../platform-util.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformGuard implements CanActivate {
  private platformUtil: PlatformUtilService;

  constructor(private injector: Injector) {
    this.platformUtil = this.injector.get<PlatformUtilService>(PlatformUtilService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currURL: string = state.url.split('?')[0];

      switch (currURL) {
        case '/login-setup-biometrics':
        case '/login-setup-pin':
          if (this.platformUtil.getDeviceType === 'web') {
            return false;
          } else {
            return true;
          }
          break;
        default:
          return true;
      }
  }
}

