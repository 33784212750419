import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ViewAgentApiUtilService {
  constructor() {}

  isViewAsAgentApi(url: string): boolean {
    // Remove baseUrl from the url
    var endpoint = url.replace(`${environment.apiEndpoint}/${environment.apiVersion}`, '');

    switch (endpoint.toLowerCase()) {
      case '/agent/getdashboard':
      case '/agent/getdetail':
      case '/agent/getactivitysummarydetail':
      case '/contact/getdashboard':
      case '/contact/getlisting':
      case '/contact/getsurveyform':
      case '/contact/getcallhistorylisting':
      case '/contact/getselection':
      case '/policy/getdashboard':
      case '/policy/getdetail' :
      case '/policy/getlisting':
      case '/policy/getallcoverage':
      case '/policy/getotherlisting':
      case '/newbusiness/getdetail':
      case '/newbusiness/getdashboard':
      case '/newbusiness/getotherlisting':
      case '/search/getcontactlisting':
      case '/search/getnblisting':
      case '/search/getpolicylisting':
      case '/search/getcalendarlisting':
      case '/search/getservicelisting':
      case '/search/getclaimlisting':
      case '/search/getnotificationlisting':
      case '/content/getcontentrequestlist':
      case '/content/getcontentrequestdetails':
      case '/content/request/getdetails':
      case '/content/doa/getdetails':
      case '/content/getcontentlist':
      case '/content/getcontentdetails':
      case '/content/getcontentresourcedetails':
      case '/customer/GetLeadContacts':
      case '/resource/content/getgeneratedlinksfolder ':
      case '/resource/content/getfolder':
      case '/resource/content/getcategory':
      case '/resource/content/getdoacategory':
      case '/resource/content/getcampaigns':
      case '/resource/content/getcampaigndetail':
      case '/resource/content/getcampaigncontent':
      case '/resource/content/getcampaignarticle':
      case '/calendar/gethistorylisting':
      case '/performance/getdashboard':
      case '/performance/getteamlisting':
      case '/performance/getteamsummary':
      case '/performance/getpersistency':
      case '/performance/persistency/getteams':
      case '/ranking/getdashboard':
      case '/ranking/getteams':
      case '/reward/getlisting':
        return true;
      default:
        return false;
    }
  }
}
