// Angular
import { Injectable, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SideTabOutletService {
  templateHistory: (TemplateRef<any> | undefined)[] = [undefined];
  currentHistoryIndex: number = 0;
  showSiteTab: boolean = false;

  constructor(private location: Location) {}

  get sideTabTemplate(): TemplateRef<any> | undefined {
    return this.templateHistory[this.currentHistoryIndex];
  }

  open(siteTabTemplate: TemplateRef<any>): void {
    this.templateHistory = [
      ...this.templateHistory.slice(0, this.currentHistoryIndex + 1),
      siteTabTemplate,
    ];

    this.currentHistoryIndex = this.templateHistory.length - 1;
    this.showSiteTab = true;
    this.pushHistoryState();
  }

  goToHistoryIndex(targetIndex: number): void {
    this.currentHistoryIndex = targetIndex;
  }

  previousHistory(): void {
    this.currentHistoryIndex--;
  }

  nextHistory(): void {
    this.currentHistoryIndex++;
  }

  pushHistoryState(): void {
    let url: string = this.location.path();

    this.location.replaceState(url, '', {
      sideTabOutletHistoryIndex: this.currentHistoryIndex - 1,
    });
  }

  close() {
    this.currentHistoryIndex = -1;
  }
}
