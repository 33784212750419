// Angular
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
// Component
import { SideTabOutletService } from './site-tab-outlet.service';
// Animation
import { SlideInOut } from '@animations/slide-in-out.animation';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'side-tab-outlet',
  templateUrl: './side-tab-outlet.component.html',
  styleUrls: ['./side-tab-outlet.component.scss'],
  animations: [SlideInOut],
})
export class SideTabOutletComponent implements OnInit {
  locationSubs: SubscriptionLike;

  constructor(
    private location: Location,
    private sideTabService: SideTabOutletService,
  ) {
    this.locationSubs = this.location.subscribe((event: any) => {;
      if (event.state && !event.state.modalOutletHistoryIndex) {
        this.sideTabService.previousHistory();
      }
    });
  }

  ngOnInit(): void {}

  get siteTabTemplate(): TemplateRef<any> | undefined {
    return this.sideTabService.sideTabTemplate;
  }
}
