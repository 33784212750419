/* eslint-disable @typescript-eslint/naming-convention */
export enum LeadTemperature {
  NEW = 'New',
  HOT = 'Hot',
  WARM = 'Warm',
  COLD = 'Cold',
  EXPIRED = 'Expired',
}

export enum LeadTemperatureColor {
  NEW = 'pink',
  HOT = 'red',
  WARM = 'yellow',
  COLD = 'blue',
  EXPIRED = 'violet',
}
