import { Injectable } from '@angular/core';
import { BaseRequestBody, BaseRequestMessage } from '@models/base-request';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GenerateMsgUtilService {

  constructor() { }

  /**
   * Function to add in headerInfo in request message
   * Pass in Request body which extends BaseRequestBody
   *
   * @param requestBody
   * @returns return back request message
   */
  generateRequestMessage<T extends BaseRequestBody>(requestBody: T): BaseRequestMessage<T> {
    let requestMsg = new BaseRequestMessage<T>();

    requestMsg = {
      headerInfo: {
        timestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS') + 'Z',
        language: 'en-US',
      },
      body: requestBody,
    };

    return requestMsg;
  }
}
