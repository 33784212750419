import { Injectable } from '@angular/core';
import { Store } from '../classes/store.class';

export class AuthState {
  agentCode: string | null;
  password: string | null;
  agentIdNo: string | null;
  otpTransactionCode: string | null;
  otpPhoneNo: string | null;
  loginId: string | null;
  phoneNumber: string | null;
  email: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(new AuthState());
  }

  get userIsLoggedIn(): boolean {
    return !!this.state.agentCode;
  }

  get phoneNumber(): string {
    return this.state.phoneNumber;
  }

  get email(): string {
    return this.state.email;
  }

  setUser(user: AuthState) {
    this.setState({
      ...this.state,
      ...user,
    });
  }

  getUser() {
    return this.state;
  }

  clearAuth(): void {
    this.setState({
      agentCode: null,
      password: null,
      agentIdNo: null,
      otpTransactionCode: null,
      otpPhoneNo: null,
      loginId: null,
      phoneNumber: null,
      email: null,
    });
  }
}
